import React, { useEffect, useState } from 'react';
import FormHeader from './FormHeader';
import { Form, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { moreThanMinDate } from 'helpers/utils';
import { handleUpdate } from 'helpers/newCritical';
import SaveBtn from './SaveBtn';
import { useNavigate } from 'react-router-dom';
import { getNextStage } from './FormUtil';

const CommForm = ({ job, dept, jobStageItem }) => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [commItem, setCommItem] = useState({
    requestedCallOffDate: '',
    scheduledDelDate: '',
    commercialSpecialInstructions: '',
    processingSecondCheckDate: '',
    proformaSent: false,
    signoffSent: false
  });

  useEffect(() => {
    if (job)
      setCommItem(prev => ({
        ...prev,
        ...job,
        scheduledDelDate: job.wdJobInfo?.requiredDate ?? ''
      }));
  }, [job]);

  const handleSaveAndUpdateStage = async (data, jobStageItem) => {
    // Update Sale Data
    await handleUpdate(data, setLoading, 'CommercialData');

    const nextStage = await getNextStage(
      jobStageItem.stageId,
      jobStageItem.disableStage
    );

    if (nextStage) {
      const newJobStageItem = {
        ...jobStageItem,
        stageId: nextStage.id
      };

      handleUpdate(newJobStageItem, setLoading, 'UpdateStage').then(res => {
        if (res)
          setTimeout(() => {
            nav('/new-critical/nc-dashboard');
            window.location.reload();
          }, 1000);
      });
    }
  };

  return (
    <>
      <FormHeader name={dept.name} colour={dept.data}>
        <Form className="p-3">
          <Row className="mb-3 g-3">
            <Form.Group as={Col} controlId="callOffDate">
              <Form.Label>Requested Call Off Date</Form.Label>
              <DatePicker
                className="form-control"
                placeholderText="Call Off Date"
                onChange={date =>
                  setCommItem(prev => ({
                    ...prev,
                    requestedCallOffDate: new Date(date).toISOString()
                  }))
                }
                dateFormat={'dd/MM/yyyy'}
                selected={
                  moreThanMinDate(commItem.requestedCallOffDate)
                    ? new Date(commItem.requestedCallOffDate)
                    : new Date()
                }
              />
            </Form.Group>

            <Form.Group as={Col} controlId="processSecondCheck">
              <Form.Label>Processing second check Date</Form.Label>
              <DatePicker
                className="form-control"
                placeholderText="Checked?"
                onChange={date =>
                  setCommItem(prev => ({
                    ...prev,
                    processingSecondCheckDate: new Date(date).toISOString()
                  }))
                }
                dateFormat={'dd/MM/yyyy'}
                selected={
                  moreThanMinDate(commItem.processingSecondCheckDate)
                    ? new Date(commItem.processingSecondCheckDate)
                    : new Date()
                }
              />
            </Form.Group>

            <Form.Group as={Col} controlId="scheduledDelDate">
              <Form.Label>Scheduled Delivery Date</Form.Label>
              <DatePicker
                className="form-control"
                placeholderText="Req. Del date"
                onChange={date =>
                  setCommItem(prev => ({
                    ...prev,
                    scheduledDelDate: new Date(date).toISOString()
                  }))
                }
                dateFormat={'dd/MM/yyyy'}
                selected={
                  moreThanMinDate(commItem.scheduledDelDate)
                    ? new Date(commItem.scheduledDelDate)
                    : new Date()
                }
                readOnly
              />
            </Form.Group>
          </Row>

          <Form.Group
            className="mb-3 g-3"
            as={Col}
            controlId="specialInstructions"
          >
            <Form.Label>Special Instructions</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={commItem.commercialSpecialInstructions || ''}
              onChange={e =>
                setCommItem(prev => ({
                  ...prev,
                  commercialSpecialInstructions: e.target.value
                }))
              }
            />
          </Form.Group>

          <Row>
            <Form.Group as={Col} id="proforma">
              <Form.Check
                type="checkbox"
                id="proforma-sent"
                label="Proforma sent?"
                checked={commItem.proformaSent}
                onChange={e =>
                  setCommItem(prev => ({
                    ...prev,
                    proformaSent: e.target.checked
                  }))
                }
              />
            </Form.Group>

            <Form.Group as={Col} id="signoff">
              <Form.Check
                type="checkbox"
                id="sing-off-sent"
                label="Sign off sent?"
                checked={commItem.signoffSent}
                onChange={e =>
                  setCommItem(prev => ({
                    ...prev,
                    signoffSent: e.target.checked
                  }))
                }
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group
              style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
            >
              <SaveBtn
                text="Save"
                loadingText="Saving..."
                loading={loading}
                handleClick={() =>
                  handleUpdate(commItem, setLoading, 'CommercialData')
                }
              />
              <SaveBtn
                loading={loading}
                text={
                  !job.mrpSent
                    ? 'Save & Move to Production'
                    : 'Save & Move to Purchasing'
                }
                loadingText="Processing..."
                handleClick={() =>
                  handleSaveAndUpdateStage(commItem, jobStageItem)
                }
              />
            </Form.Group>
          </Row>
        </Form>
      </FormHeader>
    </>
  );
};

CommForm.propTypes = {
  job: PropTypes.object,
  dept: PropTypes.object,
  jobStageItem: PropTypes.object
};

export default CommForm;
