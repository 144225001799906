import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedBin: null
};

export const binSlice = createSlice({
  name: 'bin',
  initialState,
  reducers: {
    applyBin: (state, action) => {
      state.selectedBin = { ...action.payload };
    }
  }
});

// Action creators are generated for each case reducer function
export const { applyBin } = binSlice.actions;

export default binSlice.reducer;
