import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { hasPermissionWithPath } from "helpers/permissionHelper";
import PropTypes from 'prop-types';

const AuthRoute = ({ children }) => {
    const location = useLocation();
    const hasPermission = hasPermissionWithPath(location.pathname);
    return hasPermission ? children : <Navigate to="/author/403Error" />;
};
AuthRoute.propTypes = {
    children: PropTypes.object,
}


export default AuthRoute;