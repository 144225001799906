import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Calendar from 'components/common/Calendar';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getAllEvaUsers } from 'services/userServices';

const MeetingDetailHeader = ({ title, eventDate, author, regFee }) => {

  const [user, setUser] = useState({});
  const isMounted = useRef(null);
  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();
    getAllEvaUsers(controller.signal)
      .then(res => {
        if (!isMounted.current) return null;

        if (res?.length){
          const item = res.find(i => i.userId == author);
          setUser(item);
        } 

       
      })
      .catch(err => {
        console.log('Error in user fetch', err);
      });

    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, [author]);

  return (
    <Card className="p-0 mb-3">
      <Card.Body className="overflow-hidden">
        <Row className="flex-center">
          <Col>
            <Flex>
              <Calendar {...eventDate} />
              <div className="fs--1 ms-2 flex-1">
                <h5 className="fs-0 text-capitalize">{title}</h5>
                <p className="mb-0 text-capitalize">
                  by
                  <Link className=" ms-1 " to="#!">
                    {user? user.name : ""}
                  </Link>
                </p>
                <span className="fs-0 text-warning fw-semi-bold">{regFee}</span>
              </div>
            </Flex>
          </Col>
          <Col md="auto" className="mt-4 mt-md-0">
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

MeetingDetailHeader.propTypes = {
  title: PropTypes.string,
  eventDate: PropTypes.object,
  author: PropTypes.number,
  regFee: PropTypes.string
};

export default MeetingDetailHeader;
