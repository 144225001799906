import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  getAtRiskODRs,
  getSupplierDeliveryChanges,
  getAgedODRs,
  getNewODRs,
  getNNCCustomers,
  getNewCCFs,
  getFaultSourceODRs
} from 'services/meeting-management-services/odrStageDashboardService';
import { Spinner } from 'react-bootstrap';

const CustomersFocusWidget = () => {
  const [atRisksODRs, setAtRisksODRs] = useState({ data: -1, url: '' });
  const [supplierDeliveryChanges, setSDC] = useState({ data: -1, url: '' });
  const [agedODRs, setAgedODRs] = useState({ data: -1, url: '' });
  const [newODRs, setNewODRs] = useState({ data: -1, url: '' });
  const [newCCFs, setNewCCFs] = useState({ data: -1, url: '' });
  const [ncCustomers, setNcCustomers] = useState({ data: -1, url: '' });

  const [faultSource, setFaultSource] = useState([]);

  useEffect(() => {
    getAtRiskODRs().then(res => {
      setAtRisksODRs(res);
    });
    getSupplierDeliveryChanges().then(res => {
      setSDC(res);
    });
    getAgedODRs().then(res => {
      setAgedODRs(res);
    });
    getNewODRs().then(res => {
      setNewODRs(res);
    });
    getNNCCustomers().then(res => {
      setNcCustomers(res);
    });
    getNewCCFs().then(res => {
      setNewCCFs(res);
    });
    getFaultSourceODRs().then(res => {
      setFaultSource(res);
    });
  }, []);

  return (
    <>
      <Row>
        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="At Risks ODRs"
            data={atRisksODRs.data}
            loading={atRisksODRs.data == -1}
            url={atRisksODRs.url}
            faultSources={faultSource}
          />
        </Col>

        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="Supplier Delivery Changes"
            data={supplierDeliveryChanges.data}
            loading={supplierDeliveryChanges.data == -1}
            url={supplierDeliveryChanges.url}
            faultSources={faultSource}
          />
        </Col>

        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="Aged ODRs"
            data={agedODRs.data}
            loading={agedODRs.data == -1}
            url={agedODRs.url}
            faultSources={faultSource}
          />
        </Col>

        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="New ODRs"
            data={newODRs.data}
            loading={newODRs.data == -1}
            url={newODRs.url}
            faultSources={faultSource}
          />
        </Col>

        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="New CCFs"
            data={newCCFs.data}
            loading={newCCFs.data == -1}
            url={newCCFs.url}
            faultSources={faultSource}
          />
        </Col>

        <Col xs={12} md={4} className="mb-4">
          <CardNumberWidget
            title="N&C Customers"
            data={ncCustomers.data}
            loading={ncCustomers.data == -1}
            url={ncCustomers.url}
          />
        </Col>
      </Row>
    </>
  );
};

CustomersFocusWidget.propTypes = {
  meetingId: PropTypes.string
};

export default CustomersFocusWidget;
const CardNumberWidget = ({ title, data, loading, url, faultSources }) => {
  const id = title.replace(/\s+/g, '').replace(/[^a-zA-Z]/g, ''); // Tạo id duy nhất
  console.log(id);
  return (
    <Card>
      <Card.Body>
        <Row className="flex-between-center g-0 h-100 align-items-center justify-content-center text-center">
          <Col>
            <h6 className="mb-2 text-900">{title}</h6>

            <h4 className="fs-3 fw-normal text-700 mb-0">
              {loading ? (
                <Spinner
                  variant="primary"
                  animation="border"
                  role="status"
                ></Spinner>
              ) : (
                <>
                  <div className="accordion" id={`accordion${id}`}>
                    <div
                      className="accordion-item"
                      style={{
                        border: 'none',
                        background: 'none',
                        boxShadow: 'none'
                      }}
                    >
                      <h2 className="accordion-header" id={`heading${id}`}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                          }}
                        >
                          <a
                            href={url == undefined ? '#' : url}
                            target={url == undefined ? '' : '_blank'}
                            rel="noopener noreferrer"
                            style={{
                              textAlign: 'center',
                              flexGrow: 1,
                              position: 'absolute',
                              right: '47%'
                            }}
                          >
                            {title === 'N&C Customers'
                              ? [...new Set(data.flatMap(x => x.customerNames))]
                                  .length
                              : data.length}
                          </a>

                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${id}`}
                            aria-expanded="false"
                            aria-controls={`collapse${id}`}
                            style={{
                              width: '5px',
                              border: 'none',
                              background: 'none',
                              boxShadow: 'none',
                              marginLeft: 'auto'
                            }}
                          ></button>
                        </div>
                      </h2>

                      {data && data.length > 0 && (
                        <div
                          className="accordion-collapse collapse"
                          id={`collapse${id}`}
                          aria-labelledby={`heading${id}`}
                          data-bs-parent={`#accordion${id}`}
                        >
                          <div
                            className="accordion-body"
                            style={{ fontSize: '12px', textAlign: 'left' }}
                          >
                            <div className="table-responsive scrollbar">
                              {title === 'N&C Customers' && (
                                <div className="customer-list">
                                  <h4>
                                    <strong>New & Critical Customers</strong>
                                  </h4>

                                  {data.map((item, index) => (
                                    <div
                                      key={index}
                                      style={{ marginBottom: '10px' }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: 'bold',
                                          display: 'inline-block',
                                          marginRight: '5px'
                                        }}
                                      >
                                        {item.dayOfWeek} –
                                      </span>
                                      <span style={{ color: 'red' }}>
                                        {item.customerNames.join(', ')}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              )}
                              {faultSources && (
                                <table className="table table-bordered">
                                  {faultSources && (
                                    <thead>
                                      <tr>
                                        <th scope="col">Department</th>
                                        <th scope="col">{title}</th>
                                      </tr>
                                    </thead>
                                  )}

                                  <tbody>
                                    {faultSources &&
                                      faultSources.map((x, index) => {
                                        const existeds = data.filter(
                                          i => i.faultSource == x.id
                                        );
                                        return (
                                          <tr key={index}>
                                            <td>{x.name}</td>
                                            <td>
                                              {existeds ? existeds.length : 0}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </h4>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

CardNumberWidget.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  title: PropTypes.string,
  loading: PropTypes.bool,
  url: PropTypes.string,
  faultSources: PropTypes.array
};
