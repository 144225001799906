import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import FormHeader from './FormHeader';
import { handleUpdate } from 'helpers/newCritical';
import { moreThanMinDate } from 'helpers/utils';
import SaveBtn from './SaveBtn';
import { useNavigate } from 'react-router-dom';
import { getNextStage } from './FormUtil';
import CheckedByLink from './CheckedBy';
import UserDropdown from 'components/Dropdowns/UserDropdown';

const CsoForm = ({
  job,
  dept,
  jobStageItem,
  updateJobDetail,
  customerType
}) => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [csoItem, setCsoItem] = useState({
    introCallMade: false,
    furtherTrainingRequired: false,
    requiredDate: '',
    requestedDeliveryDate: '',
    scheduledDelDate: '',
    specialInstructions: '',
    processingSecondCheckDate: '',
    proformaSent: false,
    signoffSent: false,
    mrpSent: false
  });

  useEffect(() => {
    if (job && job.jobNo) setCsoItem(prev => ({ ...prev, ...job }));
  }, [job]);

  const handleSaveAndUpdateStage = async (data, jobStageItem) => {
    // Update Sale Data
    handleUpdate(data, setLoading, 'ProcessingData');

    updateJobDetail(data);

    const nextStage = await getNextStage(
      jobStageItem.stageId,
      jobStageItem.disableStage
    );

    if (nextStage) {
      const newJobStageItem = {
        ...jobStageItem,
        stageId: nextStage.id
      };

      handleUpdate(newJobStageItem, setLoading, 'UpdateStage').then(res => {
        if (res)
          setTimeout(() => {
            nav('/new-critical/nc-dashboard');
            window.location.reload();
          }, 1000);
      });
    }
  };

  const handleCheckedByMe = (value, key) => {
    setCsoItem(prev => ({ ...prev, [key]: value }));
  };

  return (
    <>
      <FormHeader name={dept.name} colour={dept.data}>
        <Form className="p-3">
          <Row className="mb-3 g-3">
            <Form.Group as={Col} id="introCall">
              <Form.Check
                type="checkbox"
                id="intro-call-made"
                label="Intro call made?"
                checked={csoItem.introCallMade}
                onChange={e =>
                  setCsoItem(prev => ({
                    ...prev,
                    introCallMade: e.target.checked
                  }))
                }
              />
            </Form.Group>

            <Form.Group as={Col} id="furtherTraining">
              <Form.Check
                type="checkbox"
                id="further-training-required"
                label="Further training required?"
                checked={csoItem.furtherTrainingRequired}
                onChange={e =>
                  setCsoItem(prev => ({
                    ...prev,
                    furtherTrainingRequired: e.target.checked
                  }))
                }
              />
            </Form.Group>
          </Row>

          <Form.Group
            className="mb-3 g-3"
            as={Col}
            controlId="specialInstructions"
          >
            <Form.Label>Special Instructions</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={csoItem.specialInstructions || ''}
              onChange={e =>
                setCsoItem(prev => ({
                  ...prev,
                  specialInstructions: e.target.value
                }))
              }
            />
          </Form.Group>

          <Row className="g-3">
            <Form.Group
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr'
              }}
            >
              <Form.Label style={{ marginRight: '5px' }}>
                Required Date
              </Form.Label>
              <Form.Label>Requested Delivery Date</Form.Label>
              <Form.Label>Scheduled Delivery Date</Form.Label>
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                columnGap: '1rem'
              }}
            >
              <DatePicker
                className="form-control"
                placeholderText="Required Date"
                onChange={date =>
                  setCsoItem(prev => ({
                    ...prev,
                    requiredDate: new Date(date).toISOString()
                  }))
                }
                dateFormat={'dd/MM/yyyy'}
                selected={
                  moreThanMinDate(csoItem.requiredDate)
                    ? new Date(csoItem.requiredDate)
                    : ''
                }
                readOnly
              />

              <DatePicker
                className="form-control"
                placeholderText="Req. Del date"
                onChange={date =>
                  setCsoItem(prev => ({
                    ...prev,
                    requestedDeliveryDate: new Date(date).toISOString()
                  }))
                }
                dateFormat={'dd/MM/yyyy'}
                selected={
                  moreThanMinDate(csoItem.requestedDeliveryDate)
                    ? new Date(csoItem.requestedDeliveryDate)
                    : ''
                }
                readOnly
              />

              <DatePicker
                className="form-control"
                placeholderText="Req. Del date"
                onChange={date =>
                  setCsoItem(prev => ({
                    ...prev,
                    scheduledDelDate: new Date(date).toISOString()
                  }))
                }
                dateFormat={'dd/MM/yyyy'}
                selected={
                  moreThanMinDate(csoItem.scheduledDelDate)
                    ? new Date(csoItem.scheduledDelDate)
                    : ''
                }
                readOnly
              />
            </Form.Group>
          </Row>

          <Row className="g-3">
            <Form.Group
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr'
              }}
            >
              <div>
                <Form.Label style={{ marginRight: '5px' }}>
                  Delivery Date Checked By
                </Form.Label>
                <CheckedByLink
                  handleChangeValue={handleCheckedByMe}
                  property={'deliveryDateCheckedBy'}
                />
              </div>
              <div>
                <Form.Label style={{ marginRight: '5px' }}>
                  Requested Delivery Date Checked By
                </Form.Label>
                <CheckedByLink
                  handleChangeValue={handleCheckedByMe}
                  property={'requestedDeliveryDateCheckedBy'}
                />
              </div>
              <div>
                <Form.Label style={{ marginRight: '5px' }}>
                  Scheduled Delivery Date Checked By
                </Form.Label>
                <CheckedByLink
                  handleChangeValue={handleCheckedByMe}
                  property={'scheduledDeliveryDateCheckedBy'}
                />
              </div>
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                columnGap: '1rem'
              }}
            >
              <div>
                <UserDropdown
                  selectedOptions={csoItem?.deliveryDateCheckedBy}
                  handleChange={opts =>
                    setCsoItem(prev => ({
                      ...prev,
                      deliveryDateCheckedBy: opts.value
                    }))
                  }
                  isMulti={false}
                />
              </div>
              <div>
                <UserDropdown
                  selectedOptions={csoItem?.requestedDeliveryDateCheckedBy}
                  handleChange={opts =>
                    setCsoItem(prev => ({
                      ...prev,
                      requestedDeliveryDateCheckedBy: opts.value
                    }))
                  }
                  isMulti={false}
                />
              </div>
              <div>
                <UserDropdown
                  selectedOptions={csoItem?.scheduledDeliveryDateCheckedBy}
                  handleChange={opts =>
                    setCsoItem(prev => ({
                      ...prev,
                      scheduledDeliveryDateCheckedBy: opts.value
                    }))
                  }
                  isMulti={false}
                />
              </div>
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} id="proforma">
              <Form.Check
                type="checkbox"
                id="proforma-sent"
                label="Proforma sent?"
                checked={csoItem.proformaSent}
                onChange={e =>
                  setCsoItem(prev => ({
                    ...prev,
                    proformaSent: e.target.checked
                  }))
                }
              />
            </Form.Group>

            <Form.Group as={Col} id="signoff">
              <Form.Check
                type="checkbox"
                id="sign-off-sent"
                label="Sign off sent?"
                checked={csoItem.signoffSent}
                onChange={e =>
                  setCsoItem(prev => ({
                    ...prev,
                    signoffSent: e.target.checked
                  }))
                }
              />
            </Form.Group>

            <Form.Group as={Col} id="mrp-sent">
              <Form.Check
                type="checkbox"
                id="mrp-sent?"
                label="MRP Sent?"
                checked={csoItem.mrpSent}
                onChange={e =>
                  setCsoItem(prev => ({
                    ...prev,
                    mrpSent: e.target.checked
                  }))
                }
              />
            </Form.Group>
          </Row>

          <Row className="g-3">
            <Form.Group
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr'
              }}
            >
              <Form.Label>Processing second check Date</Form.Label>
              <div>
                <Form.Label style={{ marginRight: '0.2rem' }}>
                  Processing Second Checked By
                </Form.Label>
                <CheckedByLink
                  handleChangeValue={handleCheckedByMe}
                  property={'processingSecondCheckedBy'}
                />
              </div>
            </Form.Group>
          </Row>

          <Row className="g-3">
            <Form.Group
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                columnGap: '1rem'
              }}
            >
              <DatePicker
                className="form-control"
                placeholderText="Checked?"
                onChange={date =>
                  setCsoItem(prev => ({
                    ...prev,
                    processingSecondCheckDate: new Date(date).toISOString()
                  }))
                }
                dateFormat={'dd/MM/yyyy'}
                selected={
                  moreThanMinDate(csoItem.processingSecondCheckDate)
                    ? new Date(csoItem.processingSecondCheckDate)
                    : ''
                }
              />
              <UserDropdown
                selectedOptions={csoItem?.processingSecondCheckedBy}
                handleChange={opts =>
                  setCsoItem(prev => ({
                    ...prev,
                    processingSecondCheckedBy: opts.value
                  }))
                }
                isMulti={false}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group
              style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
            >
              <SaveBtn
                loading={loading}
                text="Save"
                loadingText="...Saving"
                handleClick={() => {
                  handleUpdate(csoItem, setLoading, 'ProcessingData');
                  updateJobDetail(csoItem);
                }}
              />
              {customerType === 902 && (
                <SaveBtn
                  loading={loading}
                  text="Save & Move to Commercial"
                  loadingText="Processing..."
                  handleClick={() =>
                    handleSaveAndUpdateStage(csoItem, jobStageItem)
                  }
                />
              )}
            </Form.Group>
          </Row>
        </Form>
      </FormHeader>
    </>
  );
};

CsoForm.propTypes = {
  job: PropTypes.object,
  dept: PropTypes.object,
  jobStageItem: PropTypes.object,
  updateJobDetail: PropTypes.func,
  customerType: PropTypes.number
};

export default CsoForm;
