import React from 'react';
import { Card, Row } from 'react-bootstrap';
import ActionPlanComment from './ActionPlanComment';
import PropTypes from 'prop-types';

const ActionPlanFooter = ({id}) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row className="flex-between-center">
          <ActionPlanComment id={id}/>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ActionPlanFooter;
ActionPlanFooter.propTypes = {
  id: PropTypes.string.isRequired,
};