import { updateItem, bulkComplete } from 'services/newCritical';
import NCToast from 'components/pages/new-critical/NCToast';

const handleUpdate = async (item, setLoading, uri) => {
  let res = false;
  if (!item) return res;

  console.log(item);
  try {
    setLoading(true);
    if (uri === 'CSOFeedback' && Object.hasOwn(item, 'jobs')) {
      // This is a CSO bulk item
      // console.log('Bulk')
      res = await bulkComplete(item);
    } else {
      // console.log('Regular')
      res = await updateItem(item, uri);
    }
    NCToast(res);
  } catch (err) {
    console.error(uri, err);
    NCToast(false);
  } finally {
    setLoading(false);
  }

  return res;
};

export { handleUpdate };
