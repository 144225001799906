import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const BaseDropdown = ({ sources, selectedOptions, handleChange, isMulti = false, placeholder='' }) => {
  const [items, setItems] = useState([]);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    setItems(sources.map(x => {
      return {
        ...x, value: x.id, label: x.display
      }
    }));
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Select
      isMulti={isMulti}
      name="items"
      options={items}
      value={
        typeof selectedOptions === 'number'
          ? items?.find(x => x.value === selectedOptions)
          : selectedOptions
      }
      onChange={options => handleChange(options)}
      classNamePrefix="react-select"
      placeholder={placeholder}
    />
  );
};

BaseDropdown.propTypes = {
  selectedOptions: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  handleChange: PropTypes.func,
  isMulti: PropTypes.bool,
  sources: PropTypes.array,
  placeholder: PropTypes.string
};

export default BaseDropdown;
