import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import commentActive from 'assets/img/illustrations/comment-active.png';
import commentInActive from 'assets/img/illustrations/comment-inactive.png';

const FeedActionButtons = ({ reactions }) => {
  return (
    <Row className="g-0 fw-semi-bold text-center py-2 fs--1">
      <Col xs="auto">
        <Flex
          alignItems="center"
          className="rounded text-700 me-3 cursor-pointer"
        >
          <img
            src={reactions?.comment ? commentActive : commentInActive}
            alt=""
            width="20"
          />
          <span className="ms-1">Comments</span>
        </Flex>
      </Col>
    </Row>
  );
};

FeedActionButtons.propTypes = {
  id: PropTypes.string,
  reactions: PropTypes.object
};

export default FeedActionButtons;
