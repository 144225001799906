import React from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Authentication from "components/pages/npd/landing/Authentication";
import { getItemFromStore } from "helpers/utils";

export default function ProtectedRoute() {
    const authFromRedux = useSelector(state => state.user.isAuthenticated);
    const authFromStoreage = getItemFromStore('user', {}, sessionStorage).isAuthenticated;
    return authFromRedux || authFromStoreage ? <Outlet /> : <Authentication />;
}