import React, { useContext } from 'react';
import Rating from 'react-rating';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';

const CircleRating = ({ fractions = 1, rating, handleChange, ...rest }) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);

  const generateSymbol = (value, filled) => (
    <div
        style={{
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            fontSize: '25px',
            color: filled ? 'white' : '#aaa',
            backgroundColor: filled ? '#72b043' : '#e0e0e0',
            margin: '2px'
        }}
    >
        {value}
    </div>
);

  return (
    <Rating
      key={rating}
      start={0}
      stop={10}
      fractions={fractions}
      initialRating={rating}
      fullSymbol={Array.from({ length: 11 }, (_, i) =>
        generateSymbol(i + 1, true)
      )}
      emptySymbol={Array.from({ length: 11 }, (_, i) =>
        generateSymbol(i + 1, false)
      )}
      onChange={handleChange}
      direction={isRTL ? 'rtl' : 'ltr'}
      {...rest}
    />
  );
};

CircleRating.propTypes = {
  fractions: PropTypes.number,
  rating: PropTypes.number.isRequired,
  handleChange: PropTypes.func
};

export default CircleRating;
