import React from "react";
import PropTypes from "prop-types";

const YesNoTemplate = ({ text, textHeader, onYes, onNo }) => (
    <div className="text-700 text-white">
        <h5 className="text-white">{textHeader}</h5>
        <hr className="me-2" />
        <p>{text}</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button style={{ backgroundColor: '#2c7be5', color: 'white', border: '5px', padding: '10px 20px', marginRight: '10px' }} onClick={onYes}>Yes</button>
            <button style={{ backgroundColor: 'red', color: 'white', border: '5px', padding: '10px 20px' }} onClick={onNo}>No</button>
        </div>
    </div>
);

YesNoTemplate.propTypes = {
    text: PropTypes.string,
    textHeader: PropTypes.string,
    onYes: PropTypes.func,
    onNo: PropTypes.func
}

export default YesNoTemplate;