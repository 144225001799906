import React, { useState, useEffect, useRef } from 'react';
import { StockTableRQ } from '../models/StockTableRQ';
import {
  Button,
  Card,
  Row,
  Col,
  Form,
  FormGroup,
  Spinner
} from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { searchs } from 'services/stock-location/stockService';
import SlsProductDropdown from 'components/Dropdowns/SlsProductDropdown';
import SlsBinDropdown from 'components/Dropdowns/SlsBinDropdown';
import PartialTable from 'components/partials/stock-location/PartialTable';
import { useReactToPrint } from 'react-to-print';
import { exportToExcel } from 'helpers/exportHelper';
import SlsLocationDropdown from 'components/Dropdowns/SlsLocationDropdown';
import moment from 'moment';
const baseColumns = [
  {
    accessor: 'created',
    Header: 'Time Date',
    Cell: rowData => {
      const { created } = rowData.row.original;
      return moment.utc(created).local().format('YYYY-MM-DD HH:mm:ss');
    }
  },
  {
    accessor: 'productCode',
    Header: 'Product code'
  },
  {
    accessor: 'productDescription',
    Header: 'Product Description'
  },
  {
    accessor: 'qtyAtLocation',
    Header: 'QTY'
  },
  {
    accessor: 'binBarcode',
    Header: 'BIN'
  },
  {
    accessor: 'locationBarcode',
    Header: 'LOCATION'
  }
];

// const viewProductColumns = [
//   {
//     accessor: 'qtyAtLocation',
//     Header: 'QTY'
//   },
//   {
//     accessor: 'uom',
//     Header: 'UOM'
//   },
//   {
//     accessor: 'binBarcode',
//     Header: 'BIN'
//   },
//   {
//     accessor: 'locationBarcode',
//     Header: 'LOCATION'
//   }
// ];
// const viewBinColumns = [
//   {
//     accessor: 'productCode',
//     Header: 'Product code'
//   },
//   {
//     accessor: 'productDescription',
//     Header: 'Product Description'
//   },
//   {
//     accessor: 'mpLengthUPBox',
//     Header: 'Metres per length/units per pack'
//   },
//   {
//     accessor: 'qtyAtLocation',
//     Header: 'QTY showing in stock at location (m/units)'
//   },
//   {
//     accessor: 'actualCountLengthPacks',
//     Header: 'Actual count (lengths/packs)'
//   },
//   {
//     accessor: 'actualCountMetresEach',
//     Header: 'Actual count (metres/each)'
//   }
// ];

export default function StockSearch() {
  const startRequest = new StockTableRQ(1, 200, '');
  const [loading, setLoading] = useState(false);
  const [stocks, setStocks] = useState([]);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState(startRequest);

  const [filter, setFilter] = useState({
    isViewProduct: true,
    isViewBin: false,
    isViewLocation: false
  });

  const [bin, setBin] = useState({});
  const [product, setProduct] = useState({});
  const [location, setLocation] = useState({});
  // const [columns, setColumns] = useState([]);

  const tableRef = useRef(null);

  const [stockExport, setStockExport] = useState([]);
  const [pagingExport, setPagingExport] = useState(startRequest);
  const [totalExport, setTotalExport] = useState(0);
  const [excelDownloading, setExcelDownloading] = useState(false);
  const [printing, setPrinting] = useState(false);
  const handleBinChange = option => {
    setBin(option);
    paging.pageSize = startRequest.pageSize;
    paging.pageNum = startRequest.pageSize;
    setPaging({
      ...paging,
      binBarCode: option.barcode,
      productCode: '',
      locationBarcode: ''
    });
  };

  const handleProductCodeChange = option => {
    setProduct(option);
    paging.pageSize = startRequest.pageSize;
    paging.pageNum = startRequest.pageSize;
    setPaging({
      ...paging,
      productCode: option.productCode,
      binBarCode: '',
      locationBarcode: ''
    });
  };

  const handleLocationChange = option => {
    setLocation(option);
    paging.pageSize = startRequest.pageSize;
    paging.pageNum = startRequest.pageSize;
    setPaging({
      ...paging,
      locationBarcode: option.barcode,
      productCode: '',
      binBarCode: ''
    });
  };

  const handleViewMode = (key) => {
    setStocks([]);
    setStockExport([]);
    pagingExport.pageSize = 200;
    setPaging({ ...startRequest });
    setTotal(0);

    switch (key) {
      case 'bin':
        setFilter({
          isViewProduct: false,
          isViewBin: true,
          isViewLocation: false
        });
        break;
      case 'product':
        setFilter({
          isViewProduct: true,
          isViewBin: false,
          isViewLocation: false
        });
        break;
      default:
        setFilter({
          isViewProduct: false,
          isViewBin: false,
          isViewLocation: true
        });
        break;
    }
    // if (value) {
    //   setColumns(viewProductColumns);
    // } else {
    //   setColumns(viewBinColumns);
    // }
  };

  const handleExport = async type => {
    let request = {
      ...paging,
      binBarCode: bin.barcode,
      productCode: '',
      locationBarcode: '',
      isGetAll: true
    };
    if (filter.isViewProduct) {
      request = {
        ...paging,
        productCode: product.productCode,
        binBarCode: '',
        locationBarcode: '',
        isGetAll: true
      };
    } else if (filter.isViewLocation) {
      request = {
        ...paging,
        productCode: '',
        binBarCode: '',
        locationBarcode: location.barcode,
        isGetAll: true
      };
    }
    if (type == 'print') {
      setPrinting(true);
    } else {
      setExcelDownloading(true);
    }

    await searchs(request)
      .then(res => {
        if (res) {
          pagingExport.pageSize = res.total;
          pagingExport.pageNum = 1;
          setStockExport(res.items);
          setPagingExport(pagingExport);
          setTotalExport(res.total);
          if (type == 'print') {
            onPrint();
          } else {
            const fileName = filter.isViewBin
              ? 'stock_by_binbarcode_' + bin.barcode
              : filter.isViewProduct
              ? 'stock_by_product_' + product.productCode
              : 'stock_by_location' + location.barcode;
            const sheetName = filter.isViewBin
              ? bin.barcode
              : filter.isViewProduct
              ? product.productCode
              : location.barcode;

            exportToExcel(res.items, fileName, sheetName, baseColumns);

            // if (isViewProduct) {
            //   exportToExcel(
            //     res.items,
            //     'stock_by_product_' + product.productCode,
            //     product.productCode,
            //     // viewProductColumns
            //     baseColumns
            //   );
            // } else {
            //   exportToExcel(
            //     res.items,
            //     'stock_by_binbarcode_' + bin.barcode,
            //     bin.barcode,
            //     // viewBinColumns
            //     baseColumns
            //   );
            // }
          }
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (type == 'print') {
          setPrinting(false);
        } else {
          setExcelDownloading(false);
        }
      });
  };

  const onPrint = useReactToPrint({
    content: () => tableRef.current
  });

  useEffect(() => {
    // setColumns(viewProductColumns);
  }, []);

  const handleLoad = async () => {
    paging.pageNum = 1;
    setPaging(paging);
    await loadData();
  };

  const loadData = async () => {
    if (
      filter.isViewProduct &&
      (product.productCode == '' || product.productCode == undefined)
    ) {
      return;
    } else if (
      filter.isViewBin &&
      (bin.barcode == '' || bin.barcode == undefined)
    ) {
      return;
    } else if (
      filter.location &&
      (location.barcode == '' || location.barcode == undefined)
    ) {
      return;
    }

    setLoading(true);
    let request = {
      ...paging,
      binBarCode: bin.barcode,
      productCode: '',
      locationBarcode: ''
    };
    if (filter.isViewProduct) {
      request = {
        ...paging,
        productCode: product.productCode,
        binBarCode: '',
        locationBarcode: ''
      };
    } else if (filter.isViewLocation) {
      request = {
        ...paging,
        productCode: '',
        binBarCode: '',
        locationBarcode: location.barcode
      };
    }
    setPaging(request);
    await searchs(request)
      .then(res => {
        if (res) {
          setStocks(res.items);
          setTotal(res.total);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const gotoPage = index => {
    index += 1;
    if (index <= 0) return;
    paging.pageNum = index;
    setPaging(paging);
    loadData();
  };

  const nextPage = () => {
    const page = paging.pageNum + 1;
    if (page > Math.ceil(total / paging.pageSize)) return;
    paging.pageNum = page;
    setPaging(paging);
    loadData();
  };

  const previousPage = () => {
    const page = paging.pageNum - 1;
    if (page <= 0) return;
    paging.pageNum = page;
    setPaging(paging);
    loadData();
  };

  return (
    <>
      <PageHeader
        title="Stocks"
        description={`Manage and view all available stocks from this page.`}
        className="mb-3"
      ></PageHeader>

      <Row className="g-3 mb-3">
        <Col md={5}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title className="mb-3 mx-1">
                <FormGroup className="d-flex flex-row">
                  <Form.Check
                    label="Product Code"
                    type="radio"
                    id="fluid-mode-switch"
                    checked={filter.isViewProduct}
                    onChange={() => {
                      handleViewMode('product');
                    }}
                  />
                  <Form.Check
                    style={{ marginLeft: '15px' }}
                    label="Bin"
                    type="radio"
                    id="fluid-mode-switch"
                    checked={filter.isViewBin}
                    onChange={() =>
                      handleViewMode('bin')
                    }
                  />
                  <Form.Check
                    style={{ marginLeft: '15px' }}
                    label="Location"
                    type="radio"
                    id="fluid-mode-switch"
                    checked={filter.isViewLocation}
                    onChange={() =>
                      handleViewMode('location')
                    }
                  />
                </FormGroup>
              </Card.Title>
              <div>
                <Row>
                  <Form.Group className="mb-3">
                    {filter.isViewProduct ? (
                      <SlsProductDropdown
                        selectedOptions={product}
                        handleChange={opts => handleProductCodeChange(opts)}
                        isMulti={false}
                      />
                    ) : filter.isViewBin ? (
                      <SlsBinDropdown
                        selectedOptions={bin?.id}
                        handleChange={opts => handleBinChange(opts)}
                        isMulti={false}
                      />
                    ) : (
                      <SlsLocationDropdown
                        selectedOptions={location?.id}
                        handleChange={opts => handleLocationChange(opts)}
                        isMulti={false}
                      />
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3">
                    {filter.isViewProduct
                      ? product.productDescription
                      : filter.isViewBin
                      ? bin.description
                      : location.description}
                  </Form.Group>
                </Row>
              </div>
              <div>
                <Button
                  style={{
                    float: 'center'
                  }}
                  variant={'primary'}
                  onClick={() => handleLoad()}
                >
                  Load
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card>
        <Card.Body className="overflow-hidden p-lg-6">
          {stocks.length > 0 ? (
            <Row
              style={{
                paddingBottom: '10px'
              }}
              className="align-items-center justify-content-between"
            >
              <Col md={7}></Col>
              <Col md={4}>
                <Row className="align-items-center justify-content-between">
                  <Col
                    md={6}
                    className="align-items-center justify-content-between"
                  >
                    {!excelDownloading ? (
                      <Button
                        style={{
                          width: '100%',
                          height: '60px'
                        }}
                        variant={'primary'}
                        onClick={() => {
                          handleExport('excel');
                        }}
                      >
                        Export excel
                      </Button>
                    ) : (
                      <div className="text-center">
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                        ></Spinner>
                      </div>
                    )}
                  </Col>
                  <Col
                    md={6}
                    className="align-items-center justify-content-between"
                  >
                    {!printing ? (
                      <Button
                        style={{
                          width: '100%',
                          height: '60px'
                        }}
                        variant={'primary'}
                        onClick={() => {
                          handleExport('print');
                        }}
                      >
                        Print
                      </Button>
                    ) : (
                      <div className="text-center">
                        <Spinner
                          variant="primary"
                          animation="border"
                          role="status"
                        ></Spinner>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : null}
          {/* <Row className="align-items-center justify-content-between"><PartialTable
                        data={stocks}
                        columns={columns}
                        paging={paging}
                        total={total}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        gotoPage={gotoPage}
                    /> </Row> */}

          <Row className="align-items-center justify-content-between">
            <PartialTable
              data={stocks}
              // columns={columns}
              columns={baseColumns}
              paging={paging}
              total={total}
              nextPage={nextPage}
              previousPage={previousPage}
              gotoPage={gotoPage}
              loading={loading}
            />
          </Row>

          {printing ? (
            <Row
              style={{ display: 'none' }}
              className="align-items-center justify-content-between"
            >
              <PartialTable
                data={stockExport}
                // columns={columns}
                columns={baseColumns}
                paging={pagingExport}
                total={totalExport}
                nextPage={nextPage}
                previousPage={previousPage}
                gotoPage={gotoPage}
                tableRef={tableRef}
              />
            </Row>
          ) : null}
        </Card.Body>
      </Card>
    </>
  );
}
