import React, { useEffect, useState } from 'react';
import OptionListSelect from 'components/Dropdowns/OptionListSelect';
import { handleUpdate } from 'helpers/newCritical';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SaveBtn from '../forms/SaveBtn';

const ModalHeader = jobStageItem => {
  const [stageItem, setStageItem] = useState({
    jobNo: '',
    customerCode: '',
    stageId: 0
  });
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();

  useEffect(() => {
    if (jobStageItem) setStageItem(prev => ({ ...prev, ...jobStageItem }));
  }, [jobStageItem]);

  return (
    <div className="p-4 border border-bottom-2 bg-light dark__bg-dark fixed-top position-sticky d-flex align-items-center">
      <Col xs={4} className="me-auto">
        <h1 className="text-truncate">
          {stageItem.customerCode} {stageItem.jobNo && `- ${stageItem.jobNo}`}
        </h1>
      </Col>
      <Col xs={4} className="mx-auto">
        {stageItem.stageId != 4126 ?
          <OptionListSelect
            optionKey="new.and.critical.job.department"
            selected={stageItem.stageId}
            changeFn={opt =>
              setStageItem(prev => ({ ...prev, stageId: opt.value }))
            }
            isMulti={false}
            placeholder="Select stage"
            disableStage={stageItem.disableStage}
            customerType={jobStageItem.customerType}
            enabledStage={jobStageItem.enabledStage}
          /> : ''}
      </Col>
      <Col xs={2} className="ms-auto">
        {stageItem.stageId != 4126 ?
          <SaveBtn
            text="Update Stage"
            loadingText="Updating..."
            loading={loading}
            handleClick={() => {
              handleUpdate(stageItem, setLoading, 'UpdateStage').then(res => {
                if (res)
                  setTimeout(() => {
                    nav('/new-critical/nc-dashboard');
                    window.location.reload();
                  }, 1000);
              });
            }}
          /> : ''}
      </Col>
    </div>
  );
};

ModalHeader.propTypes = {
  jobStageItem: PropTypes.object
};

export default ModalHeader;
