import axios from 'axios';
import { EVA_URL } from '../../helpers/urlHelper';

axios.defaults.withCredentials = true;

// const baseURL = `https://eva.emplas.co.uk/Ajax/ODR/GetODRByStage.ashx`;
const baseURL = `${EVA_URL}/eva-odr-dashboard`;
const fullState = ["1879"
    ,"2087","2069","1880","1937","2962","4941","2327","2511",
    "2512","2513","2514","2515","3338","6237","4976",
    "5837","2970","2949","2562","2328","2329","2497","2510"
];

const requestBase = {
    stages: fullState.join(","),  // Convert array to comma-separated string
    query: "",
    users: "2441,1346,4453,1498,4343,17,1742,4621,544,3600,3689,1492,269,3856,1350,5109,3696,4031,4597,1743,1731,2554,2580,2091,3557,243,1325,4,55,581,4234,4387,3681,4288,1635,1256,605,2200,3653,3646,3650,264,318,224,3871,614,2144,1437,4526,1528,2469,454,3695,1,2435,4754",
    customerTypes: "902,147,889,146",
    odrAge: "",
    odrAtRisk: "atrisk"
};

const evaLink = process.env.REACT_APP_EVA_URL + "/Internal/ODR/ODRStageDashboard.aspx";
const getAtRiskODRs = async () => {
    try {
        const response = await axios({
            method: 'post',
            url: `${baseURL}/get-at-risk-odrs`,
            data: requestBase,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        if (response && response.data.success && response.data.data !== null && response.data.data !== undefined) {
            const params = new URLSearchParams(requestBase);
            return {
                data : response.data.data,
                url: `${evaLink}?${params.toString()}`
            }
        } else {
            return 0;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return 0;
    }
};
const getFaultSourceODRs = async () => {
    try {
        const response = await axios({
            method: 'get',
            url: `${EVA_URL}/OptionList?optionKey=wd.odr.fault.source`,
            data: requestBase,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        return response.data;
        
    } catch (error) {
        console.error('Error fetching data:', error);
        return 0;
    }
};

const getSupplierDeliveryChanges = async () => {
    try {
        const request =  {...requestBase, stages: "6237", odrAtRisk: "" } ;
        const response = await axios({
            method: 'post',
            url: `${baseURL}/get-at-risk-odrs`,
            data: request,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        if (response && response.data.success && response.data.data !== null && response.data.data !== undefined) {
            const params = new URLSearchParams(request);
            return {
                data : response.data.data,
                url: `${evaLink}?${params.toString()}`
            }
        } else {
            return 0;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return 0;
    }
};

const getAgedODRs = async () => {
    try {
        const request = {...requestBase, odrAge : "old", odrAtRisk: "" } ;
        const response = await axios({
            method: 'post',
            url: `${baseURL}/get-at-risk-odrs`,
            data: request ,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        if (response && response.data.success && response.data.data !== null && response.data.data !== undefined) {
            const params = new URLSearchParams(request);
            return {
                data : response.data.data,
                url: `${evaLink}?${params.toString()}`
            }
        } else {
            return 0;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return 0;
    }
};

const getNewODRs = async () => {
    try {
        const request =  {...requestBase, odrAge : "new", odrAtRisk: "" } ;
        const response = await axios({
            method: 'post',
            url: `${baseURL}/get-at-risk-odrs`,
            data: request ,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        if (response && response.data.success && response.data.data !== null && response.data.data !== undefined) {
            const params = new URLSearchParams(request);
            return {
                data : response.data.data,
                url: `${evaLink}?${params.toString()}`
            }
        } else {
            return 0;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return 0;
    }
};
const getNNCCustomers = async () => {
    try {
        const response = await axios({
            method: 'get',
            url: `${baseURL}/get-n&c-customers`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        if (response && response.data.success && response.data.data !== null && response.data.data !== undefined) {
            return {
                data : response.data.data,
                url: 'https://eva.emplas.co.uk/Internal/Reports/MeetingReport.aspx'
            }
        } else {
            return 0;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return 0;
    }
} 
const getNewCCFs = async () => {
    try {
        const response = await axios({
            method: 'get',
            url: `${baseURL}/get-new-ccfs`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const newCffsURL = process.env.REACT_APP_EVA_URL + "/Internal/CCF/CCFSearch.aspx?mode=newccfs";
        if (response && response.data.success && response.data.data !== null && response.data.data !== undefined) {
            return {
                data : response.data.data,
                url: `${newCffsURL}`
            }
        } else {
            return 0;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return 0;
    }
} 


export {
    getAtRiskODRs,
    getSupplierDeliveryChanges,
    getAgedODRs,
    getNewODRs,
    getNNCCustomers,
    getNewCCFs,
    getFaultSourceODRs
};

