import { Card, Form, Row, Col } from 'react-bootstrap';
import { departments } from '../../../../../data/TempData';
import UserDropdownV2 from 'components/Dropdowns/CustomerServices/UserDropdownV2';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DepartmentDropdown from 'components/Dropdowns/CustomerServices/DepartmentDropdown';
import PriorityDropdown from 'components/Dropdowns/CustomerServices/PriorityDropdown';
import ProgressDropdown from 'components/Dropdowns/CustomerServices/ProgressDropdown';
import InputDatePicker from './InputDatePicker';
import { getItemFromStore } from 'helpers/utils';
import { joinDataMultiDropdown } from 'helpers/dropdownFuncHelper';
import {
  getActionPlan,
  patchActionPlan
} from 'services/customer-service/scActionPlanService';

import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

const ActionPlanRightInfo = ({ id }) => {
  console.log(id);
  const currentUser = getItemFromStore('user', {}, sessionStorage);
  const [tags, setTags] = useState([]);
  const defaultValues = {
    id: 0,
    progress: 0,
    priority: 1,
    reporter: 0,
    relativeJobNumbers: '',
    assignees: [],
    requestParticipants: [],
    departments: [],
    targetDate: new Date()
  };

  const [formData, setFormData] = useState(defaultValues);

  const handleChangeTag = newTags => {
    const uniqueTags = Array.from(new Set(newTags));
    setTags(uniqueTags);
    handleChange('relativeJobNumbers', uniqueTags.join(','));
  };

  useEffect(() => {
    const relatedJobTags = formData?.relativeJobNumbers
      ?.split(',')
      .filter(tag => tag.trim() !== '');
    console.log(relatedJobTags);
    if (relatedJobTags?.length > 0) {
      setTags(relatedJobTags);
    } else {
      setTags([]);
    }
  }, [formData]);

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = () => {
    getActionPlan(id).then(res => {
      if (res?.data && res?.data.id > 0) {
        const mapData = mapRSData(res?.data);
        console.log(mapData)
        setFormData(mapData);
      }
    });
  };

  const mapRSData = data => {
    if (data) {
      let mapRs = { ...data };
      if (data.targetDate) {
        mapRs.targetDate = new Date(data.targetDate);
      }
      return mapRs;
    }
    return defaultValues;
  };

  const handleChange = async (name, value) => { 
    let request = {
      ...formData,
      [name]: value
    };
    setFormData(request);

    var rqData = mapRQData(request);
    await patchActionPlan(rqData, id);
    // fetchData();
  };

  const mapRQData = (data) => {
    if (data) {
      let mapRq = { ...data };
      console.log(mapRq);
  
      if (mapRq?.targetDate) {
        mapRq.targetDate = new Date(
          Date.UTC(
            mapRq.targetDate.getFullYear(),
            mapRq.targetDate.getMonth(),
            mapRq.targetDate.getDate()
          )
        );
      } else {
        mapRq.targetDate = undefined;
      }
  
      if (data?.progress) {
        mapRq.progress = data.progress.value || 1;
      }
  
      if (data?.reporter) {
        mapRq.reporter = data.reporter.value // data.reporter.value > 0 ? data.reporter.value : currentUser.userId;
      }
  
      if (data?.priority) {
        mapRq.priority = data.priority.value;
      }
  
      if (data?.assignees) {
        mapRq.assignees = joinDataMultiDropdown(data.assignees);
      }
  
      if (data?.requestParticipants) {
        mapRq.requestParticipants = joinDataMultiDropdown(data.requestParticipants);
      }
  
      if (data?.departments) {
        mapRq.departments = joinDataMultiDropdown(data.departments);
      }
  
      return mapRq;
    }
  
    return null;
  };
  

  return (
    <Card>
      <Card.Header as="h5">Other Info</Card.Header>
      <Card.Body className="bg-light">
        <Row>
          <Col md="6">
            <InputDatePicker
              column="targetDate"
              displayName="Target Date"
              selected={formData?.targetDate}
              handleChange={handleChange}
            />
          </Col>
          <Col md="6">
            <ProgressDropdown
              label="Status"
              fieldName="progress"
              isMulti={false}
              placeholder="Status..."
              handleChange={handleChange}
              value={formData?.progress ? formData?.progress : 1}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <UserDropdownV2
              label="Responsible persons"
              fieldName="assignees"
              handleChange={handleChange}
              placeholder="Responsible persons..."
              value={formData?.assignees}
            />
          </Col>
          <Col md="6">
            <UserDropdownV2
              label="Request participants"
              fieldName="requestParticipants"
              handleChange={handleChange}
              placeholder="Request participants..."
              value={formData?.requestParticipants}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <UserDropdownV2
              label="Reporter"
              fieldName="reporter"
              handleChange={handleChange}
              placeholder="Reporter..."
              value={
                formData?.reporter ? formData?.reporter : currentUser.userId
              }
              isMulti={false}
            />
          </Col>
          <Col md="6">
            <DepartmentDropdown
              label="Departments"
              fieldName="departments"
              closeMenuOnSelect={false}
              isMulti
              options={departments}
              placeholder="Department"
              handleChange={handleChange}
              value={formData?.departments}
            />
          </Col>
        </Row>
        <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Col md="6" style={{ display: 'flex', flexDirection: 'column' }}>
            <Form.Group controlId="relativeJobNumbers">
              <Form.Label>Relative Job Numbers</Form.Label>
              <TagsInput
                inputProps={{ placeholder: 'Relative Job Numbers' }}
                value={tags}
                onChange={handleChangeTag}
              />
            </Form.Group>
          </Col>
          <Col md="6" style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ flex: 1 }}>
              <PriorityDropdown
                label="Priority"
                fieldName="priority"
                closeMenuOnSelect={false}
                isMulti={false}
                placeholder="Priority ..."
                handleChange={handleChange}
                value={formData?.priority ? formData?.priority : 0}
              />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
ActionPlanRightInfo.propTypes = {
  id: PropTypes.number,
  handleChange: PropTypes.func
};

export default ActionPlanRightInfo;
