import axios from "axios";
import store from "../store";
import { applyIsAuthenticated, applyUser, applyUserName, applyIsFullPermission, applyPermissions } from "slices/authSlice";
import { EVA_URL, URL } from '../helpers/urlHelper';
import { getItemFromStore, setItemToStore } from "helpers/utils";

const { dispatch } = store;

axios.defaults.withCredentials = true;
const userKey = 'user';
// const checkToken = async (token, email) => {
//     if (!token || !email) return { message: 'No email or token params', success: false };

//     const res = await axios({
//         method: 'get',
//         url: `${URL}/passwordless-auth/login-redirect?verifyToken=${token}&email=${email}`,
//     })
//         .catch(err => {
//             console.log('ERR', err);
//             return { message: 'Error caught', success: false };
//         });

//     if (res && res.status === 200) dispatch(applyIsAuthenticated(res.data.success));

//     return { message: 'Successful response', success: res.data.success };
// }

const checkCookie = async () => {
    const res = await axios({
        method: 'get',
        url: `${EVA_URL}/auth/isLoggedIn`,
    })
        .catch(err => {
            console.log('ERR', err.response);
            if (err.response.status === 401)
                return { message: 'Unauthorised', success: false };
            else
                return { message: 'Something went wrong', success: false };
        });

    if (res && res.status === 200) {
        const resPermission = await axios({
            method: 'get',
            url: `${URL}/permission`,
        })
            .catch(err => {
                console.log('ERR', err.response);
                if (err.response.status === 401)
                    return { message: 'Unauthorised', success: false };
                else
                    return { message: 'Something went wrong', success: false };
            });

        if (resPermission && resPermission.status === 200) {
            dispatch(applyIsFullPermission(resPermission.data?.data?.isFullPermission));
            dispatch(applyPermissions(resPermission.data?.data?.permissionsData));
            dispatch(applyIsAuthenticated(res.data.success));
            dispatch(applyUser(res.data.userId));
            dispatch(applyUserName(res.data.name));

            const currStore = getItemFromStore(userKey, {}, sessionStorage);
            setItemToStore(userKey, JSON.stringify({ ...currStore, userId: res.data?.userId, isAuthenticated: res.data?.success, userEmail: res.data?.email, userName: res.data?.name }), sessionStorage);

            const store = getItemFromStore(userKey, {}, sessionStorage);
            setItemToStore(userKey, JSON.stringify({ ...store, permissions: resPermission.data?.data?.permissionsData, isFullPermission: resPermission.data?.data?.isFullPermission }), sessionStorage);

            return { message: 'Successful response', success: res.data.success, userId: res.data.userId };
        }
    }
    return { message: 'Faliure response', success: false, userId: 0 };
}

export { checkCookie }; 
