import axios from 'axios';
import { URL } from '../../helpers/urlHelper';
import { BaseTableRS } from 'components/pages/stock-location/models/BaseTable';
import { objectToQueryString, getMessage400Request } from '../../helpers/utils'
import { ApiResponse } from '../../helpers/stockLocationHelpers'

axios.defaults.withCredentials = true;

const searchLocations = async (request) => {
    const result = new ApiResponse();
    const res = await axios({
        method: 'get',
        url: `${URL}/locations?${objectToQueryString(request)}`,
    })
        .catch((res) => {
            console.log('ERROR: ', { ...res });
            result.message = getMessage400Request(res);
            return result;
        });

    if (res.status !== 200)
        return new BaseTableRS([], 0);

    return new BaseTableRS(res.data?.data?.items, res.data?.data?.total);
}
const getLocationById = async (id) => {
    const result = new ApiResponse();
    const res = await axios({
        method: 'get',
        url: `${URL}/locations/${id}`,
    })
        .catch((res) => {
            console.log('ERROR: ', { ...res });
            result.message = getMessage400Request(res);
            return result;
        });

    if (res.status !== 200) {
        return result;
    }
    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}

const postLocation = async (location) => {
    const result = new ApiResponse();
    if (!location) return result;
    const res = await axios({
        method: 'post',
        url: `${URL}/locations`,
        data: { ...location },
        config: {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            }
        },
    })
        .catch((res) => {
            console.log('ERROR: ', { ...res });
            result.message = getMessage400Request(res);
            return result;
        });
    if (res.status !== 200) {
        return result;
    }

    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}

const patchLocation = async (location, id) => {
    const result = new ApiResponse();
    if (!location || id <= 0) return result;
    const res = await axios({
        method: 'patch',
        url: `${URL}/locations/${id}`,
        data: { ...location },
        config: {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            }
        },
    })
        .catch((res) => {
            console.log('ERROR: ', { ...res });
            result.message = getMessage400Request(res);
            return result;
        });

    if (res.status !== 200) {
        return result;
    }
    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}

const disableLocation = async (id) => {
    const result = new ApiResponse();
    console.log(id);
    if (id <= 0) return result;
    const res = await axios({
        method: 'delete',
        url: `${URL}/locations/${id}`,
    })
        .catch((res) => {
            console.log('ERROR: ', { ...res });
            result.message = getMessage400Request(res);
            return result;
        });
    if (res.status !== 200) {
        return result;
    }
    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}
const enableLocation = async (id) => {
    const result = new ApiResponse();
    if (id <= 0) return result;
    const res = await axios({
        method: 'patch',
        url: `${URL}/locations/${id}/enable-status`,
    })
        .catch((res) => {
            console.log('ERROR: ', { ...res });
            result.message = getMessage400Request(res);
            return result;
        });
    if (res.status !== 200) {
        return result;
    }
    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}
export { searchLocations, getLocationById, postLocation, patchLocation, disableLocation, enableLocation };