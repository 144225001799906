import Swal from 'sweetalert2';

export const toastConfig = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'dark'
};

export const swalToast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  }
});

const swalToastBase = (status, message, background) => {
  swalToast.fire({
    icon: status,
    title: message,
    background: background,
    iconColor: '#fff',
    color: '#fff'
  });
};

export const swalToastSuccess = message => {
  swalToastBase('success', message, '#5cb85c');
};

export const swalToastError = message => {
  swalToastBase('error', message, '#d9534f');
};

export const swalToastInfo = message => {
  swalToastBase('info', message, '#5bc0de');
};

export const swalToastWarning = message => {
  swalToastBase('warning', message, '#f0ad4e');
};
