import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedArea: null
};

export const areaSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    applyArea: (state, action) => {
      state.selectedArea = { ...action.payload };
    }
  }
});

// Action creators are generated for each case reducer function
export const { applyArea } = areaSlice.actions;

export default areaSlice.reducer;
