import React from "react";
import { Placeholder, Form, Row, Col, PlaceholderButton } from "react-bootstrap";

const SkeletonForm = () => {
    return (
        <>
            <Row className="mb-3 g-3">
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
            </Row>

            <Row className="mb-3 g-3">
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
            </Row>

            <Row className="mb-3 g-3">
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
            </Row>

            <Row className="mb-3 g-3">
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
            </Row>

            <Row className="mb-3 g-3">
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
            </Row>

            <Row className="mb-3 g-3">
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
            </Row>

            <Row className="mb-3 g-3">
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
                <Placeholder as={Col} animation="glow">
                    <Placeholder as={Form.Control} xs={12} />
                </Placeholder>
            </Row>
            <Row className="mb-3 g-3">
                <Placeholder as={Col} animation="glow">
                    <PlaceholderButton xs={2} size="lg" variant="primary" />
                </Placeholder>
            </Row>
        </>
    )
}

export default SkeletonForm;