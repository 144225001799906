import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import PartialTable from 'components/partials/stock-location/PartialTable';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { getAllEvaUsers } from 'services/userServices';
import UsersInCellWidget from 'components/common/users/UsersInCellWidget';
import moment from 'moment';
import { useSortBe }  from 'hooks/useBeHandle';

const MeetingTable = ({
  data,
  paging,
  total,
  nextPage,
  previousPage,
  gotoPage,
  colSort,
  handleSort
}) => {
  const [users, setUsers] = useState([]);
  const isMounted = useRef(null);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();
    getAllEvaUsers(controller.signal)
      .then(res => {
        if (!isMounted.current) return null;

        if (res?.length) setUsers(res);
      })
      .catch(err => {
        console.log('Error in user fetch', err);
      });
    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, []);

  const originColumns = [
    {
      accessor: 'id',
      Header: 'ID',
      isVisible: false
    },
    {
      accessor: 'title',
      Header: 'Title',
      Cell: rowData => {
        const { title, id } = rowData.row.original;

        return (
          <Link to={`/customer-service/meetings/${id}`}>

          {/* <Link to={`/meeting-management/${id}/customer-focus-dashboard`}> */}
            <Flex alignItems="center">
              <div className="flex-1">
                <h5 style={{ color: '#0052CC' }} className="mb-0 fs--1">
                  {title}
                </h5>
              </div>
            </Flex>
          </Link>
        );
      }
    },
    {
      accessor: 'venue',
      Header: 'Venue'
    },
    {
      accessor: 'start',
      Header: 'Date',
      Cell: rowData => {
        const { start } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">
                {start && moment.utc(start).local().format('DD/MM/YYYY')}
              </h5>
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'reOccurent',
      Header: 'Re-occurent'
    },
    {
      accessor: 'createdBy',
      Header: 'Created By',
      Cell: rowData => {
        const { createdBy } = rowData.row.original;
        return (
          <UsersInCellWidget usersText={createdBy?.toString() } showName users={users} />
        );
      }
    },
    {
      accessor: 'managedBy',
      Header: 'Managed By',
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        const { managedBy } = rowData.row.original;
        return (
          <UsersInCellWidget usersText={managedBy?.toString()} showName users={users} />
        );
      }
    },
    {
      accessor: 'participants',
      Header: 'Attendees',
      headerProps: { style: { minWidth: '10px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' },
      Cell: rowData => {
        const { participants } = rowData.row.original;
        return (
          <UsersInCellWidget
            usersText={participants?.toString()}
            users={users}
          />
        );
      }
    },
    {
      accessor: 'description',
      Header: 'Objective'
    }
  ];

  const { reNewColumns } = useSortBe(
    originColumns,
    colSort,
    setColumns,
    columns
  );

  useEffect(() => {
    reNewColumns();
  }, [colSort]);

  return (
    <>
      <PartialTable
        data={data}
        columns={columns}
        paging={paging}
        total={total}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        beSortOption={{ canSort: true, handleSort: handleSort }}
        textAlign="center"
      />
    </>
  );
};

MeetingTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  paging: PropTypes.object,
  colSort: PropTypes.object,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  handleSort: PropTypes.func,
};

export default MeetingTable;
