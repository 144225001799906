import React, { useState, useEffect } from 'react';
import { Card, Row, Col, FormLabel } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import BinTable from 'components/partials/stock-location/bins/BinTable';
import { searchBins } from 'services/stock-location/binServices';
import { BaseTableRQ } from '../models/BaseTable';
import SlsBinDropdown from 'components/Dropdowns/SlsBinDropdown';
import SlsLocationDropdown from 'components/Dropdowns/SlsLocationDropdown';
import BaseDropdown from 'components/Dropdowns/BaseDropdown';

export default function BinList() {
  const defaultPaging = new BaseTableRQ(1, 100);
  const [bins, setBins] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({ status: 0 });
  const [paging, setPaging] = useState({ ...defaultPaging, ...filter });

  const handleFilter = (key, option) => {
    let request = { ...defaultPaging };
    switch (key) {
      case 'status':
        setFilter({
          ...filter,
          status: option.id
        });
        request = { ...request, ...filter, status: option.id };
        break;
      case 'bin':
        setFilter({
          ...filter,
          barcode: option.barcode
        });
        request = { ...request, ...filter, barcode: option.barcode };
        break;
      case 'location':
        setFilter({
          ...filter,
          locationBarcode: option.barcode
        });
        request = { ...request, ...filter, locationBarcode: option.barcode };
        break;
      default:
        break;
    }
    setPaging(request);
    loadBins(request);
    return;
  };

  useEffect(() => {
    loadBins(paging);
  }, []);

  const loadBins = pagingRq => {
    searchBins(pagingRq)
      .then(res => {
        if (res) {
          setBins(res.items);
          setTotal(res.total);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const gotoPage = index => {
    index += 1;
    if (index <= 0) return;
    paging.pageNum = index;
    setPaging(paging);
    loadBins(paging);
  };

  const nextPage = () => {
    const page = paging.pageNum + 1;
    if (page > Math.ceil(total / paging.pageSize)) return;
    paging.pageNum = page;
    setPaging(paging);
    loadBins(paging);
  };

  const previousPage = () => {
    const page = paging.pageNum - 1;
    if (page <= 0) return;
    paging.pageNum = page;
    setPaging(paging);
    loadBins(paging);
  };

  return (
    <>
      <PageHeader
        title="Bin List"
        description={`Manage and view all available Bins from this page.`}
        className="mb-3"
      ></PageHeader>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Row className="flex-between-center">
                <Col
                  xs={1}
                  sm="auto"
                  className="d-flex align-items-center pe-0"
                >
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0"></h5>
                </Col>
                <Col xs={3}>
                  <FormLabel>Bin</FormLabel>
                  <SlsBinDropdown
                    selectedOptions={0}
                    handleChange={opts => {
                      console.log(opts);
                      handleFilter('bin', opts);
                    }}
                    placeholder="Filter Bin..."
                    isMulti={false}
                  />
                </Col>
                <Col xs={3} className="ms-auto ps-0">
                  <FormLabel>Location</FormLabel>
                  <SlsLocationDropdown
                    selectedOptions={filter?.location?.id}
                    handleChange={opts => {
                      console.log(opts);
                      handleFilter('location', opts);
                    }}
                    placeholder="Filter Location..."
                    isMulti={false}
                  />
                </Col>
                <Col xs={3} className="ms-auto ps-0">
                  <FormLabel>Status</FormLabel>
                  <BaseDropdown
                    sources={[
                      {
                        id: 0,
                        display: 'Live'
                      },
                      {
                        id: 1,
                        display: 'Disabled'
                      }
                    ]}
                    selectedOptions={filter?.status}
                    handleChange={opts => handleFilter('status', opts)}
                    isMulti={false}
                  />
                  {/* <Form.Group>
                      <Form.Select
                        size="sm"
                        aria-label="Status"
                        value={selectedStatus}
                        onChange={e => handleFilter('status', opts)}
                      >
                        <option>-- Status --</option>
                        <option value="0">Live</option>
                        <option value="1">Disabled</option>
                      </Form.Select>
                    </Form.Group> */}
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="p-2 overflow-hidden">
              <BinTable
                data={bins}
                paging={paging}
                total={total}
                nextPage={nextPage}
                previousPage={previousPage}
                gotoPage={gotoPage}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
