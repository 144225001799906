
const updateProjectDetails = (project) => {
    // Filter empty items
    const items = project.items.filter((x) => x.notes !== '');
    const updatedItems = [];

    // Assign user IDs to actions
    for (let i of items) {
        const copy = { ...i };
        const current = [...i.actions];

        for (let j = 0; j < current.length; j++) {
            current[j] = {
                ...i.actions[j],
                assignedUsers: i.actions[j].assignedUsers && i.actions[j].assignedUsers.map(x => {
                    return {
                        id: x.userId ? x.userId : x.id,
                        name: x.label ? x.label : x.name
                    }
                }),
            };
        }
        copy.actions = current;
        updatedItems.push(copy);
    }

    project.items = updatedItems;

    const date = new Date();
    project = {
        ...project,
        created: date.toISOString(),
    };

    return project;
}

export { updateProjectDetails }
