import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';

const InputDatePicker = ({ column, displayName, selected, handleChange, useUTC=true }) => {
  const [tempDate, setTempDate] = useState('');

  useEffect(() => {
    let dateValue = selected;

    if (typeof selected === 'string' && selected) {
      dateValue = new Date(selected);
    }

    setTempDate(
      dateValue
        ? (useUTC ? dateValue.toLocaleDateString('en-GB', { timeZone: 'UTC' }) : dateValue.toLocaleDateString('en-GB'))
        : ''
    );
  }, [selected, useUTC]);

  const handleDateChange = (e) => {
    const value = e.target.value;
    if (value === '') {
      setTempDate('');
      handleChange(column, undefined); // Đặt `undefined` khi xóa ngày
      return;
    }

    setTempDate(value);
    const parts = value.split('/');
    if (
      parts.length === 3 &&
      parts[0].length === 2 &&
      parts[1].length === 2 &&
      parts[2].length === 4
    ) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10);
      const year = parseInt(parts[2], 10);

      const newDate = useUTC
        ? new Date(Date.UTC(year, month - 1, day))
        : new Date(year, month - 1, day);

      if (
        newDate.getFullYear() === year &&
        newDate.getMonth() === month - 1 &&
        newDate.getDate() === day
      ) {
        handleChange(column, newDate);
      }
    }
  };

  return (
    <Form.Group controlId={column}>
      <Form.Label>{displayName}</Form.Label>
      <DatePicker
        selected={selected ? new Date(selected) : null} 
        onChange={(newDate) => {
          const newDateFormatted = newDate
            ? (useUTC
                ? new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()))
                : newDate)
            : undefined;
          handleChange(column, newDateFormatted);
          setTempDate(newDate ? newDateFormatted.toLocaleDateString('en-GB') : ''); 
        }}
        customInput={
          <CustomDateInput
            formControlProps={{
              placeholder: 'dd/mm/yyyy',
              value: tempDate,
              onChange: handleDateChange,
            }}
          />
        }
      />
    </Form.Group>
  );
};

InputDatePicker.propTypes = {
  column: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.object,
  ]),
  handleChange: PropTypes.func,
  useUTC: PropTypes.bool,
};

export default InputDatePicker;
