import React from "react";
import { Row, Col } from 'react-bootstrap';
import RunningActionPlans from './RunningActionPlans';
import PageHeader from 'components/common/PageHeader';
const ActionPlanDashboard = () => {
  return (
    <>
      <PageHeader
        title="Action Plans"
        description={`Manage and view all available Action Plans from this page.`}
        className="mb-3"
      ></PageHeader>
      <Row className="g-3 mb-3">
        <Col lg={12}>
          <RunningActionPlans/>
        </Col>
      </Row>
    </>
  );
};

export default ActionPlanDashboard;
