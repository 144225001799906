import { URL } from '../../helpers/urlHelper';
import { baseSearch, basePost } from 'services/base-services/baseService'

const baseURL = `${URL}/sm-sections`;
const search = async (request) => {
    return await baseSearch(baseURL, request);
}

const post = async (request) => {
    return await basePost(baseURL, request);
}

export {
    search,
    post,
};

