import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import SitesTable from 'components/partials/stock-location/sites/SiteTable';
import { searchSites } from 'services/stock-location/siteServices';
import { BaseTableRQ } from '../models/BaseTable';
import { useDispatch } from 'react-redux';
import { applySite } from '../../../../slices/siteSlice';

export default function SiteList() {
  const defaultPaging = new BaseTableRQ(1, 100);
  const [sites, setSites] = useState([]);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({ ...defaultPaging });

  const dispatch = useDispatch();
  dispatch(applySite(null));

  const [selectedStatus, setSelectedStatus] = useState(0);

  const handleStatusChange = event => {
    setSelectedStatus(event.target.value);
    let newStatus = '';
    if (event.target.value !== '-- Status --') {
      newStatus = event.target.value;
    }
    const request = { ...defaultPaging, status: newStatus };
    setPaging(request);
    loadSites(request);
  };

  useEffect(() => {
    loadSites({ ...paging, status: selectedStatus });
  }, []);

  const loadSites = pagingRq => {
    searchSites(pagingRq)
      .then(res => {
        if (res) {
          setSites(res.items);
          setTotal(res.total);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const gotoPage = index => {
    index += 1;
    if (index <= 0) return;
    paging.pageNum = index;
    setPaging(paging);
    loadSites(paging);
  };

  const nextPage = () => {
    const page = paging.pageNum + 1;
    if (page > Math.ceil(total / paging.pageSize)) return;
    paging.pageNum = page;
    setPaging(paging);
    loadSites(paging);
  };

  const previousPage = () => {
    const page = paging.pageNum - 1;
    if (page <= 0) return;
    paging.pageNum = page;
    setPaging(paging);
    loadSites(paging);
  };

  return (
    <>
      <PageHeader
        title="Site List"
        description={`Manage and view all available Sites from this page.`}
        className="mb-3"
      ></PageHeader>
      <Card>
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0"></h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
              <div id="orders-actions">
                <Form.Group>
                  <Form.Select
                    size="sm"
                    aria-label="Status"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                  >
                    <option>-- Status --</option>
                    <option value="0">Live</option>
                    <option value="1">Disabled</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="overflow-hidden p-lg-2 align-items-center justify-content-between">
          <SitesTable
            data={sites}
            paging={paging}
            total={total}
            nextPage={nextPage}
            previousPage={previousPage}
            gotoPage={gotoPage}
          />
        </Card.Body>
      </Card>
    </>
  );
}
