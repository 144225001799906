import React from "react";
import ProjectFormItem from "./ProjectFormItem";
import { Form } from "react-bootstrap"
import PropTypes from 'prop-types';

const NewField = ({ newTitle, titleChange, newValue, valueChange, button }) => {

    return (
        <ProjectFormItem 
            id="new-input"
            title={
                <Form.Control as="input" 
                    className="p-0 border-0 shadow-none"
                    value={newTitle} 
                    placeholder="Click Here To Enter a Title"
                    onChange={titleChange}
                />} 
            body={newValue}
            placeholder={'Click Here To Enter notes'}
            valueChange={valueChange}
            button={button}
        />
    )
}

NewField.propTypes = {
    newTitle: PropTypes.string,
    newValue: PropTypes.string,
    titleChange: PropTypes.func,
    valueChange: PropTypes.func,
    button: PropTypes.element,
}

export default NewField;