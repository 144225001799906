import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav, Collapse } from 'react-bootstrap';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import classNames from 'classnames';
import AppContext from 'context/Context';
import { hasPermission } from 'helpers/permissionHelper';


const CollapseItems = ({ route }) => {
  const { pathname } = useLocation();

  const openCollapse = childrens => {
    const checkLink = children => {
      if (children.to === pathname) {
        return true;
      }
      return (
        Object.prototype.hasOwnProperty.call(children, 'children') &&
        children.children.some(checkLink)
      );
    };
    return childrens.some(checkLink);
  };

  const [open, setOpen] = useState(openCollapse(route.children));

  // const isFullPermission = useSelector(state => state.user.isFullPermission);
  // const permissions = useSelector(state => state.user.permissions);

  // console.log(isFullPermission, permissions)
  return (
    hasPermission(route) ? (
      <Nav.Item as="li">
        <Nav.Link
          onClick={() => {
            setOpen(!open);
          }}
          className={classNames('dropdown-indicator cursor-pointer', {
            'text-500': !route.active
          })}
          aria-expanded={open}
        // {...route}
        >
          <NavbarVerticalMenuItem route={route} />
        </Nav.Link>
        <Collapse in={open}>
          <Nav className="flex-column nav" as="ul">
            <NavbarVerticalMenu routes={route.children} />
          </Nav>
        </Collapse>
      </Nav.Item>
    ) : null
  );
};

CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool
  }).isRequired
};

const NavbarVerticalMenu = ({ routes }) => {
  const {
    config: { showBurgerMenu },
    setConfig
  } = useContext(AppContext);

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  // const stateIsFullPermission = useSelector(state => state.user.isFullPermission);
  // const sessionIsFullPermission = getItemFromStore('user', {}, sessionStorage)?.isFullPermission;
  // const isFullPermission = stateIsFullPermission || sessionIsFullPermission;

  // const statePermissions = useSelector(state => state.user.permissions);
  // const sessionPermissions = getItemFromStore('user', {}, sessionStorage)?.permissions;
  // let permissions;
  // if (statePermissions) {
  //   permissions = statePermissions
  // }
  // else if (sessionPermissions) {
  //   permissions = sessionPermissions;
  // }

  // console.log(isFullPermission, permissions)
  return routes.map(route => {
    if (!route.children) {
      return (
        hasPermission(route) ? (
          <Nav.Item as="li" key={route.name} onClick={handleNavItemClick}>
            <NavLink
              end={route.exact}
              to={route.to}
              state={{ open: route.to === '/authentication-modal' }}
              className={({ isActive }) =>
                isActive ? 'active nav-link' : 'nav-link'
              }
            >
              <NavbarVerticalMenuItem route={route} />
            </NavLink>
          </Nav.Item>
        ) : null
      );
    }
    return <CollapseItems route={route} key={route.name} />;
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes))
    .isRequired
};

export default NavbarVerticalMenu;
