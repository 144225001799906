import { createSlice } from '@reduxjs/toolkit';
// import { getItemFromStore } from 'helpers/utils';

// const {  } = getItemFromStore('user', {}, sessionStorage);
const initialState = {
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        applyUser: (state, action) => {
            if (action.payload) {
                state.userId = action.payload;
            }
        },
        applyUserName: (state, action) => {
            if (action.payload) {
                state.userName = action.payload;
            }
        },
        applyToken: (state, action) => {
            state.token = action.payload;
            state.isAuthenticated = true;
        },
        applyIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },
        applyIsFullPermission: (state, action) => {
            state.isFullPermission = action.payload;
        },
        applyPermissions: (state, action) => {
            state.permissions = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { applyUser, applyUserName, applyToken, applyIsAuthenticated, applyIsFullPermission, applyPermissions } = authSlice.actions;

export default authSlice.reducer;