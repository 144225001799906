import React from "react";
import ActionsChart from './ActionsChart';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Background from "components/common/Background";
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import PropTypes from 'prop-types';

const ActionsComplete = ({ totalComplete, totalActions }) => {
    return (
      <Card className="h-md-100">
        <Background image={bg1} className="bg-card" />
        <Card.Header className="pb-0">
          <h6 className="mb-0 mt-2">Actions Complete</h6>
        </Card.Header>
  
        <Card.Body as={Flex} alignItems="end" justifyContent="between">
          <div>
            <h2 className="mb-1 text-700 fw-normal lh-1 fs-2">{totalComplete ?? 0}%</h2>
          </div>
          <div>
            <ActionsChart complete={totalComplete} />
            <p className="mb-0 mt-4 text-center fs--2 text-500">
              Total Actions: <span className="text-800"> {totalActions} </span>
            </p>
          </div>
        </Card.Body>
      </Card>
    );
  };

ActionsComplete.propTypes = {
    totalComplete: PropTypes.number.isRequired,
    totalActions: PropTypes.number.isRequired,
}

export default ActionsComplete;