import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Table, Spinner } from 'react-bootstrap';

const AdvanceTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
  tableRef,
  loading = false,
  beSortOption = {}
}) => {
  return (
    <SimpleBarReact>
      <Table {...getTableProps(tableProps)} ref={tableRef}>
        <thead className={headerClassName}>
          <tr>
            {headers.map((column, index) => (
              <th
              key={index}
              {...column.getHeaderProps(
                beSortOption.handleSort ? column.headerProps : column.getSortByToggleProps()
              )}
              onClick={
                beSortOption.handleSort
                  ? () => beSortOption.handleSort(column)
                  : column.getSortByToggleProps().onClick
              }
            >
                {column.render('Header')}
                {beSortOption.canSort ? (
                  column.isBeSorted ? (
                    column.isBeSortedDesc ? (
                      <span className="sort desc" />
                    ) : (
                      <span className="sort asc" />
                    )
                  ) : (
                    <span className="sort" />
                  )
                ) : column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className="sort desc" />
                    ) : (
                      <span className="sort asc" />
                    )
                  ) : (
                    <span className="sort" />
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading && [
            <tr key="table-row-loading" className="text-center">
              <td colSpan={headers.length}>
                <Spinner
                  variant="primary"
                  animation="border"
                  role="status"
                ></Spinner>
              </td>
            </tr>
          ]}
          {page &&
            page.length > 0 &&
            !loading &&
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} className={rowClassName} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        {...cell.getCellProps(cell.column.cellProps)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          {page &&
            !page.length &&
            !loading && [
              <tr key="table-row-empty" className="text-center">
                <td colSpan={headers.length}>No Results</td>
              </tr>
            ]}
        </tbody>
      </Table>
    </SimpleBarReact>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  loading: PropTypes.bool,
  tableRef: PropTypes.object,
  beSortOption: PropTypes.object
};

export default AdvanceTable;
