import React from 'react';
import PropTypes from 'prop-types';
import PartialTable from "components/partials/stock-location/PartialTable";
const columns = [
    {
        accessor: 'no',
        Header: 'No.'
    },
    {
        accessor: 'qtyAtLocation',
        Header: 'QTY'
    },
    {
        accessor: 'uom',
        Header: 'UOM'
    },
    {
        accessor: 'binBarcode',
        Header: 'BIN'
    },
    {
        accessor: 'locationBarcode',
        Header: 'LOCATION'
    }
];

const StockTable = ({ stocks, paging, total, nextPage, previousPage, gotoPage, onPageSizeChange}) => {
    return (
        <PartialTable
            data={stocks} 
            columns={columns}
            paging={paging} 
            total={total}
            nextPage = {nextPage}
            previousPage = {previousPage}
            gotoPage = {gotoPage}
            onPageSizeChange = {onPageSizeChange}
            // view = {"/stock-location/area-management/area-manager?id="}
        />
    )
}

StockTable.propTypes = {
    stocks: PropTypes.arrayOf(PropTypes.object),
    paging:  PropTypes.object,
    total: PropTypes.number,
    nextPage: PropTypes.func,
    previousPage: PropTypes.func,
    gotoPage: PropTypes.func,
    onPageSizeChange : PropTypes.func
}

export default StockTable;