import React, { useEffect, useState, useContext } from 'react';
import Header from './Header';
import Container from './Container';
import { Spinner } from 'react-bootstrap';
import AppContext from 'context/Context';
import { dashboardData } from 'services/newCritical';
import dayjs from 'dayjs';
import { getOptionsByKey } from 'services/optionLists';
import { useSearchParams } from 'react-router-dom';

export default function NCDashboard() {
  const [searchParams, setSerchParams] = useSearchParams();
  const jobno = searchParams.get('jobno');
  const dateFrom = searchParams.get('from');
  const dateTo = searchParams.get('to');

  const {
    config: { isFluid, isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const [kanbanIsFluid] = useState(isFluid);
  const [kanbanIsNavbarVerticalCollapsed] = useState(isNavbarVerticalCollapsed);

  useEffect(() => {
    setConfig('isFluid', true);
    setConfig('isNavbarVerticalCollapsed', true);

    return () => {
      setConfig('isFluid', kanbanIsFluid);
      setConfig('isNavbarVerticalCollapsed', kanbanIsNavbarVerticalCollapsed);
    };
  }, []);

  const [searchFilters, setSearchFilters] = useState({
    dateTo: dateTo ? dayjs(dateTo) : dayjs().add(1, 'month'),
    dateFrom: dateFrom ? dayjs(dateFrom) : dayjs().subtract(7, 'day'),
    keyword: jobno ?? '',
    depts: []
  });
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState({
    newAndCriticalCustomers: [],
    newAndCriticalJobs: []
  });
  const [depts, setDepartments] = useState([]);
  const [departmentsSelected, setDepartmentsSelected] = useState([]);

  const getData = async searchFilters => {
    try {
      setLoading(true);
      let data = await dashboardData(searchFilters);

      if (data) {
        setItems(data);
        setSerchParams(prev => {
          return {
            ...prev,
            from: dayjs(searchFilters.dateFrom).toISOString(),
            to: dayjs(searchFilters.dateTo).toISOString()
          };
        });
      }
    } catch (err) {
      console.log(err);
      setItems({ newAndCriticalCustomers: [], newAndCriticalJobs: [] });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(searchFilters);

    getOptionsByKey('new.and.critical.job.department')
      .then(res => {
        if (res) {
          setDepartments(res);
        } else {
          setDepartments([]);
        }
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Spinner variant="primary" animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
          <Header
            filter={searchFilters}
            setFilters={setSearchFilters}
            search={getData}
            depts={depts}
            setDepartmentsSelected={setDepartmentsSelected}
          />
          <Container
            depts={depts}
            departmentsSelected={
              departmentsSelected.length > 0 ? departmentsSelected : depts
            }
            items={items}
            search={searchFilters.keyword}
          />
        </>
      )}
    </>
  );
}
