// Check for production or development
const isDev = process.env.NODE_ENV === 'development';

const URL = isDev
  ? process.env.REACT_APP_API_TEST_URL
  : process.env.REACT_APP_API_URL;

const EVA_URL = isDev
  ? process.env.REACT_APP_EVA_TEST_API2_URL
  : process.env.REACT_APP_EVA_API2_URL;

const EVA_LOGIN = isDev
  ? process.env.REACT_APP_EVA_TEST_LOGIN_REDIRECT
  : process.env.REACT_APP_EVA_LOGIN_REDIRECT;

const SCAN_STATION_URL = isDev
  ? process.env.REACT_APP_EVA_SCAN_STATION_TEST_API
  : process.env.REACT_APP_EVA_SCAN_STATION_API;

const EVA_API_URL = process.env.EVA_API_URL;

const NC_API_URL = process.env.NC_API;

export { URL, EVA_URL, EVA_LOGIN, EVA_API_URL, NC_API_URL, SCAN_STATION_URL };
