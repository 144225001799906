import React, { useEffect, useState } from 'react';
import SalesForm from '../forms/SalesForm';
import CsoForm from '../forms/CsoForm';
import PurchasingForm from '../forms/PurchasingForm';
import ProductionForm from '../forms/ProductionForm';
import QAForm from '../forms/QAForm';
import LoadingForm from '../forms/LoadingForm';
import CommForm from '../forms/CommForm';
import TransportForm from '../forms/TransportForm';
import FeedbackForm from '../forms/FeedbackForm';
import { getFormData } from 'services/newCritical';
import PropTypes from 'prop-types';
import SkeletonForm from '../forms/SkeletonForm';
import { Modal } from 'react-bootstrap';
import ModalHeader from './ModalHeader';

const MainForm = ({ customerCode, jobNo, stageId, depts }) => {
  const [jobDetail, setJobDetail] = useState({
    customer: {},
    job: {}
  });
  const [loading, setLoading] = useState(false);
  const scrollMargin = { scrollMarginTop: '4rem' };

  const fetchJob = async (jobNo, customerCode) => {
    setLoading(true);
    let data = await getFormData(jobNo, customerCode)
      .then(res => {
        if (res && jobNo && !res.job.jobNo)
          res.job = { ...res.job[0], jobNo: jobNo, custCode: customerCode }; // assign jobno if not found
        return res;
      })
      .catch(err => {
        console.log('Error fetching data', err);
        return {};
      })
      .finally(() => setLoading(false));

    setJobDetail(data);
  };

  useEffect(() => {
    fetchJob(jobNo, customerCode).then(() => scrollToForm());
  }, [jobNo, customerCode]);

  const updateJobDetail = newJobDetail => {
    setJobDetail({
      customer: jobDetail.customer,
      job: {
        ...jobDetail.job,
        ...newJobDetail
      }
    });
  };

  const scrollToForm = () => {
    const el = document.getElementById(`form-${stageId}`);

    if (el && stageId !== 4126)
      el.scrollIntoView({ behavior: 'instant', block: 'start' });
  };

  // Should look into fetching stages instead of hard coding - espeecially if more need to be added to the disabled list!
  return (
    <>
      <ModalHeader
        stageId={stageId}
        customerCode={customerCode}
        jobNo={jobNo}
        disableStage={
          jobDetail?.job?.mrpSent
            ? 0
            : jobDetail?.job?.purchasingPODetails?.length === 0
              ? 4130
              : 0
        }
        customerType={jobDetail.customer?.customerType}
        // Only allow Purchasing if comm or cso and had bought in items
        enabledStage={((stageId === 4128 || stageId === 4129) && jobDetail?.job?.purchasingPODetails?.length > 0) ? 4130 : 0}
      />
      <Modal.Body>
        {loading ? (
          <SkeletonForm />
        ) : (
          <>
            <div id="form-4126">
              <SalesForm
                dept={depts && depts.find(x => x.id === 4126)}
                cust={jobDetail.customer}
                jobStageItem={{ stageId, customerCode, jobNo }}
              />
            </div>
            {!loading &&
              jobNo && ( // Display all forms if we have a job number.
                <>
                  <div id="form-4128" style={scrollMargin}>
                    <CsoForm
                      dept={depts && depts.find(x => x.id === 4128)}
                      job={jobDetail.job}
                      updateJobDetail={updateJobDetail}
                      jobStageItem={{
                        stageId,
                        customerCode,
                        jobNo
                      }}
                    />
                  </div>
                  <div id="form-4129" style={scrollMargin}>
                    <CommForm
                      dept={depts && depts.find(x => x.id === 4129)}
                      job={jobDetail.job}
                      jobStageItem={{
                        stageId,
                        customerCode,
                        jobNo,
                        disableStage: jobDetail?.job?.mrpSent
                          ? 0
                          : jobDetail?.job?.purchasingPODetails?.length === 0
                            ? 4130
                            : 0
                      }}
                    />
                  </div>
                  <div id="form-4130" style={scrollMargin}>
                    <PurchasingForm
                      dept={depts && depts.find(x => x.id === 4130)}
                      job={jobDetail.job}
                      jobStageItem={{ stageId, customerCode, jobNo }}
                    />
                  </div>
                  <div id="form-4131" style={scrollMargin}>
                    <ProductionForm
                      dept={depts && depts.find(x => x.id === 4131)}
                      job={jobDetail.job}
                      jobStageItem={{
                        stageId,
                        customerCode,
                        jobNo
                      }}
                    />
                  </div>
                  <div id="form-4132" style={scrollMargin}>
                    <QAForm
                      dept={depts && depts.find(x => x.id === 4132)}
                      job={jobDetail.job}
                      jobStageItem={{
                        stageId,
                        customerCode,
                        jobNo
                      }}
                    />
                  </div>
                  <div id="form-4133" style={scrollMargin}>
                    <LoadingForm
                      dept={depts && depts.find(x => x.id === 4133)}
                      job={jobDetail.job}
                      jobStageItem={{
                        stageId,
                        customerCode,
                        jobNo
                      }}
                    />
                  </div>
                  <div id="form-4134" style={scrollMargin}>
                    <TransportForm
                      dept={depts && depts.find(x => x.id === 4134)}
                      job={jobDetail.job}
                      jobStageItem={{
                        stageId,
                        customerCode,
                        jobNo
                      }}
                      customerType={jobDetail.customer?.customerType}
                    />
                  </div>

                  {jobDetail.customer?.customerType === 902 ? (
                    <div id="form-5554" style={scrollMargin}>
                      <FeedbackForm
                        dept={depts && depts.find(x => x.id === 5554)}
                        job={jobDetail.job}
                        jobStageItem={{
                          stageId,
                          customerCode,
                          jobNo
                        }}
                      />
                    </div>
                  ) : (
                    <div id="form-4135" style={scrollMargin}>
                      <FeedbackForm
                        dept={depts && depts.find(x => x.id === 4135)}
                        job={jobDetail.job}
                        jobStageItem={{
                          stageId,
                          customerCode,
                          jobNo
                        }}
                      />
                    </div>
                  )}
                </>
              )}
          </>
        )}
      </Modal.Body>
    </>
  );
};

MainForm.propTypes = {
  id: PropTypes.number,
  customerCode: PropTypes.string,
  jobNo: PropTypes.string,
  stageId: PropTypes.number,
  depts: PropTypes.array
};

export default MainForm;
