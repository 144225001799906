import React, { useEffect, useState } from 'react';
import FormHeader from './FormHeader';
import { Form, Row, Col, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { moreThanMinDate } from 'helpers/utils';
import SaveBtn from './SaveBtn';
import { handleUpdate } from 'helpers/newCritical';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { getNextStage } from './FormUtil';
import { getOptionsByKey } from 'services/optionLists';

const PurchasingForm = ({ job, dept, jobStageItem }) => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [purchItem, setPurchItem] = useState({
    jobNo: '',
    customerName: '',
    productionDate: '',
    requiredDate: '',
    mrpRequired: false,
    mrpReceived: false,
    unknown: [],
    boughtAli: [],
    boughtVs: [],
    boughtShape: [],
    compDoor: [],
    kolorplas: [],
    catDProfiles: [],
    purchasingComments: ''
  });
  const [codes, setCodes] = useState([]);
  const createEvaPoLink = (key, po) => <span key={key}><a href={`https://eva.emplas.co.uk/Internal/Purchasing/PurchaseOrder.aspx?action=view&po_no=${po}`} target="_blank" rel="noreferrer">{po}</a>{' '}</span>;

  const getPurchasingCodes = async () => {
    const suppliers = await getOptionsByKey('new.critical.purchasing.items.supplier.code');
    const products = await getOptionsByKey('new.critical.purchasing.items.product.code');
    const keys = new Set([...suppliers.map(x => x.name), ...products.map(x => x.name)]);
    let purchasingCodes = [];

    for (let key of keys) {
      const supplierCodes = suppliers.filter(x => x.name === key).flatMap(z => z.value.split(',')).filter(x => x !== '').map(x => Number(x));
      const productCodes = products.filter(x => x.name === key).flatMap(z => z.value.split(',')).filter(x => x !== '').map(x => Number(x));

      purchasingCodes.push({
        name: key,
        codes: [...supplierCodes, ...productCodes]
      });
    }
    return purchasingCodes;
  }

  const getProducts = (key, list) => {
    const codesByKey = codes?.filter(x => x.name.toLowerCase().includes(key))[0]?.codes;
    return list.filter(x => codesByKey?.some(y => y === x.productType || y === x.supplierId));
  }

  useEffect(() => {
    getPurchasingCodes().then(res => setCodes(res));
    console.log(codes)
    if (Object.entries(job).length) {
      if (job?.purchasingPODetails && job?.purchasingPODetails?.length) {
        job = {
          ...job,
          unknown: getProducts('unknown', job.purchasingPODetails),
          boughtAli: getProducts('ali', job.purchasingPODetails),
          boughtVs: getProducts('vs', job.purchasingPODetails),
          boughtShape: getProducts('shape', job.purchasingPODetails),
          compDoor: getProducts('door', job.purchasingPODetails),
          kolorplas: getProducts('kolorplas', job.purchasingPODetails),
        };
      }
      setPurchItem(prev => ({ ...prev, ...job }));
    }
  }, [job]);

  const handleSaveAndUpdateStage = async (data, jobStageItem) => {
    handleUpdate(data, setLoading, 'PurchasingData');

    const nextStage = await getNextStage(
      jobStageItem.stageId,
      jobStageItem.disableStage
    );

    if (nextStage) {
      const newJobStageItem = {
        ...jobStageItem,
        stageId: nextStage.id
      };

      handleUpdate(newJobStageItem, setLoading, 'UpdateStage').then(res => {
        if (res)
          setTimeout(() => {
            nav('/new-critical/nc-dashboard');
            window.location.reload();
          }, 1000);
      });
    }
  };

  const displayTotalQTy = (list) => {
    if (list.length > 0) {
      return list.reduce((accumulator, currentValue) => accumulator + currentValue.qTy, 0)
    }
    return 0
  }

  return (
    <>
      <FormHeader name={dept.name} colour={dept.data}>
        <Form className="p-3">
          <Row className="mb-3 g-3">
            <Form.Group as={Col} controlId="jobno">
              <Form.Label>Job Number</Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={purchItem.jobNo || ''}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="custName">
              <Form.Label>Customer Name</Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={purchItem.salesInfo?.customerName || ''}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} controlId="productionDate">
              <Form.Label>Production Date</Form.Label>
              <DatePicker
                className="form-control"
                placeholderText="Production Date"
                dateFormat={'dd/MM/yyyy'}
                selected={
                  moreThanMinDate(purchItem.wdJobInfo?.productionDate)
                    ? new Date(purchItem.wdJobInfo?.productionDate)
                    : ''
                }
                readOnly
              />
            </Form.Group>

            <Form.Group as={Col} controlId="requiredDate">
              <Form.Label>Delivery Date</Form.Label>
              <DatePicker
                className="form-control"
                placeholderText="Delivery Date"
                dateFormat={'dd/MM/yyyy'}
                selected={
                  moreThanMinDate(purchItem.wdJobInfo?.requiredDate)
                    ? new Date(purchItem.wdJobInfo?.requiredDate)
                    : ''
                }
                readOnly
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} id="mrpRequired">
              <Form.Check
                type="checkbox"
                id="mrp-required"
                label="MRP Required?"
                checked={purchItem.mrpRequired}
                onChange={e =>
                  setPurchItem(prev => ({
                    ...prev,
                    mrpRequired: e.target.checked
                  }))
                }
              />
            </Form.Group>

            <Form.Group as={Col} id="mrpReceived">
              <Form.Check
                type="checkbox"
                id="mrp-received"
                label="MRP Received?"
                disabled={!purchItem.mrpRequired || false}
                checked={purchItem.mrpReceived}
                onChange={e =>
                  setPurchItem(prev => ({
                    ...prev,
                    mrpReceived: e.target.checked
                  }))
                }
              />
            </Form.Group>
          </Row>

          <Table size="sm" bordered responsive className="mb-3 g-3">
            <thead>
              <tr>
                <th>Job Includes</th>
                <th className="text-center">Y/N</th>
                <th className="text-center">QTY</th>
                <th className="text-center">PO Nos.</th>
                <th className="text-center">Delivery Dates</th>
              </tr>
              <tr>
                <th className="text-center text-sm table-secondary" colSpan={5}>
                  Items Yet To Be Ordered
                </th>
              </tr>
              <tr>
                <td>
                  Unknown Items
                </td>
                <td className='text-center'>
                  {purchItem.unknown.length ? <span className='text-success'>Y</span> : <span className='text-danger'>N</span>}
                </td>
                <td className='text-center'>
                  {displayTotalQTy(purchItem.unknown)}
                </td>
                <td className='text-center'>N/A</td>
                <td className='text-center'>N/A</td>
              </tr>
              <tr>
                <th className="text-center text-sm table-secondary" colSpan={5}>
                  EVA Orders
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Bought in Ali</td>
                <td className="text-center">
                  {purchItem.boughtAli.length ? <span className='text-success'>Y</span> : <span className='text-danger'>N</span>}
                </td>
                <td className="text-center">
                  {displayTotalQTy(purchItem.boughtAli)}
                </td>
                <td className="text-center">
                  {purchItem.boughtAli.length
                    ? purchItem.boughtAli.map((x, i) => createEvaPoLink(i, x?.poNumber))
                    : 'N/A'}
                </td>
                <td className="text-center">
                  {purchItem.boughtAli.length
                    ? purchItem.boughtAli
                      .map(x => dayjs(x?.poDateRequired).format('DD/MM/YYYY')).join(', ')
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Bought in VS</td>
                <td className="text-center">
                  {purchItem.boughtVs.length ? <span className='text-success'>Y</span> : <span className='text-danger'>N</span>}
                </td>
                <td className="text-center">{displayTotalQTy(purchItem.boughtVs)}</td>
                <td className="text-center">
                  {purchItem.boughtVs.length
                    ? purchItem.boughtVs.map((x, i) => createEvaPoLink(i, x?.poNumber))
                    : 'N/A'}
                </td>
                <td className="text-center">
                  {purchItem.boughtVs.length
                    ? purchItem.boughtVs
                      .map(x => dayjs(x?.poDateRequired).format('DD/MM/YYYY')).join(', ')
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Bought in Shape</td>
                <td className="text-center">
                  {purchItem.boughtShape.length ? <span className='text-success'>Y</span> : <span className='text-danger'>N</span>}
                </td>
                <td className="text-center">{displayTotalQTy(purchItem.boughtShape)}</td>
                <td className="text-center">
                  {purchItem.boughtShape.length
                    ? purchItem.boughtShape.map((x, i) => createEvaPoLink(i, x?.poNumber))
                    : 'N/A'}
                </td>
                <td className="text-center">
                  {purchItem.boughtShape.length
                    ? purchItem.boughtShape
                      .map(x => dayjs(x?.poDateRequired).format('DD/MM/YYYY')).join(', ')
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Comp Door</td>
                <td className="text-center">
                  {purchItem.compDoor.length ? <span className='text-success'>Y</span> : <span className='text-danger'>N</span>}
                </td>
                <td className="text-center">{displayTotalQTy(purchItem.compDoor)}</td>
                <td className="text-center">
                  {purchItem.compDoor.length
                    ? purchItem.compDoor.map((x, i) => createEvaPoLink(i, x?.poNumber))
                    : 'N/A'}
                </td>
                <td className="text-center">
                  {purchItem.compDoor.length
                    ? purchItem.compDoor
                      .map(x => dayjs(x?.poDateRequired).format('DD/MM/YYYY')).join(', ')
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Kolorplas</td>
                <td className="text-center">
                  {purchItem.kolorplas.length ? <span className='text-success'>Y</span> : <span className='text-danger'>N</span>}
                </td>
                <td className="text-center">{displayTotalQTy(purchItem.kolorplas)}</td>
                <td className="text-center">
                  {purchItem.kolorplas.length
                    ? purchItem.kolorplas.map((x, i) => createEvaPoLink(i, x?.poNumber))
                    : 'N/A'}
                </td>
                <td className="text-center">
                  {purchItem.kolorplas.length
                    ? purchItem.kolorplas
                      .map(x => dayjs(x?.poDateRequired).format('DD/MM/YYYY')).join(', ')
                    : 'N/A'}
                </td>
              </tr>
            </tbody>
          </Table>

          <Form.Group className="mb-3 g-3" as={Col} controlId="comments">
            <Form.Label>Comments</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={purchItem.purchasingComments || ''}
              onChange={e =>
                setPurchItem(prev => ({
                  ...prev,
                  purchasingComments: e.target.value
                }))
              }
            />
          </Form.Group>

          <Row>
            <Form.Group
              style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
            >
              <SaveBtn
                loading={loading}
                text="Save"
                loadingText="Saving..."
                handleClick={() =>
                  handleUpdate(purchItem, setLoading, 'PurchasingData')
                }
              />
              <SaveBtn
                loading={loading}
                text="Save & Move to Production"
                loadingText="Processing..."
                handleClick={() =>
                  handleSaveAndUpdateStage(purchItem, jobStageItem)
                }
              />
            </Form.Group>
          </Row>
        </Form>
      </FormHeader>
    </>
  );
};

PurchasingForm.propTypes = {
  job: PropTypes.object,
  dept: PropTypes.object,
  jobStageItem: PropTypes.object
};

export default PurchasingForm;
