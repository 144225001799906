import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { searchPermissionSetups } from 'services/permissions/permissionSetupServices';

const PermissionSetupDropDown = ({ selectedOptions, handleChange, isMulti = false }) => {
  const [sites, setAreas] = useState([]);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    searchPermissionSetups({ isGetAll: true })
      .then(res => {
        setAreas(res.items.map(x => {
          let lableText = x.code + (x.name == null ? "" : " - " + x.name);
          return {
            ...x, value: x.id, label: lableText
          }
        }));
      })
      .catch(err => {
        console.log('Error in user fetch', err);
      });

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Select
      isMulti={isMulti}
      name="sites"
      options={sites}
      value={
        typeof selectedOptions === 'number'
          ? sites?.find(x => x.value === selectedOptions)
          : selectedOptions
      }
      onChange={options => handleChange(options)}
      classNamePrefix="react-select"
      placeholder="Select a user from the list..."
    />
  );
};

PermissionSetupDropDown.propTypes = {
  selectedOptions: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  handleChange: PropTypes.func,
  isMulti: PropTypes.bool
};

export default PermissionSetupDropDown;
