import React from 'react';
import { format, formatDistanceToNow, isToday } from 'date-fns';
import PropTypes from 'prop-types';

const TimeAgo = ({ timestamp }) => {
  const date = new Date(timestamp);

  const timeAgo = isToday(date)
    ? formatDistanceToNow(date, { addSuffix: true })
    : format(date, "MMMM d, yyyy 'at' h:mm a");

  return timestamp ? <span>{timeAgo}</span> : '';
};

TimeAgo.propTypes = {
  timestamp: PropTypes.string.isRequired
};

export default TimeAgo;
