import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import MeetingTable from './MeetingTable';
import { BaseTableRQ } from 'components/common/models/BaseClass';
import { searchPagingMeetings } from 'services/customer-service/scMeetingService';
import MeetingTableHeader from './MeetingTableHeader';
import PropTypes from 'prop-types';
import MeetingFilter from './MeetingFilter';
import { joinDataMultiDropdown } from 'helpers/dropdownFuncHelper';
import {
  KEY_MEETING_LIST_CONFIG
} from 'services/base-services/userSettingServices';
import { usePaginationBe, useHanleConfig }  from 'hooks/useBeHandle';

const RunningMeeting = ({ meetingId, pageSize = 10 }) => {
  const defaultRQ = { ...new BaseTableRQ(1, pageSize), meetingId: meetingId };
  const [filter, setFilter] = useState({});
  const [meetings, setMeetings] = useState([]);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({ ...defaultRQ, meetingId: meetingId });
  const [isFilter, setIsFilter] = useState(false);
  const [colSort, setColSort] = useState(undefined);

  const loadMeetings = pagingRq => {
    searchPagingMeetings(pagingRq)
      .then(res => {
        if (res) {
          setMeetings(res.items);
          setTotal(res.total);
          let cl = {
            ...pagingRq,
            sortBy: pagingRq.sortBy,
            desc: pagingRq.desc,
            isBeSortedDesc: pagingRq.desc,
            isBeSorted: pagingRq.sortBy != undefined && pagingRq.sortBy != ''
          };
          setColSort(cl);
          setPaging(cl);
          setFilter(cl);
        }
      })
      .catch(err => {
        console.log(err);
      });
    setPaging(pagingRq);
  };

  const defaultPaging = data => {
    return {
      ...data,
      pageNum: defaultRQ.pageNum,
      pageSize: defaultRQ.pageSize,
      isLike: defaultRQ.isLike,
      isGetAll: defaultRQ.isGetAll
    };
  };

  useEffect(() => {
    setIsFilter(false);
    getConfig();
  }, []);

  const toggelFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleChange = (field, value) => {
    console.log(field, value);
    setFilter({
      ...filter,
      [field]: value
    });
  };

  const handleSubmit = () => {
    let rq = mapRQData(filter);
    loadMeetings({ ...defaultRQ, ...rq });
  };

  const mapRQData = data => {
    if (data) {
      let mapRq = { ...data };
      if (data?.date && data?.date instanceof Date) {
        console.log(data.date);
        mapRq.date = data?.date?.toISOString();
      }

      mapRq.createdBys = joinDataMultiDropdown(data?.createdBys);
      mapRq.managedBys = joinDataMultiDropdown(data?.managedBys);
      mapRq.attendees = joinDataMultiDropdown(data?.attendees);
      return mapRq;
    }
    return null;
  };

  const { gotoPage, nextPage, previousPage, handleSort } = usePaginationBe(
    paging,
    total,
    loadMeetings,
    filter,
    setFilter
  );

  const { getConfig, saveConfig } = useHanleConfig(
    KEY_MEETING_LIST_CONFIG,
    filter,
    mapRQData,
    defaultRQ,
    defaultPaging,
    loadMeetings
  );

  return (
    <>
      <Card>
        <Row style={{ padding: '10px' }}>
          <MeetingTableHeader
            toggelFilter={() => toggelFilter()}
            meetingId={meetingId}
            style={{ padding: '10px' }}
          />
          {isFilter && (
            <Col lg={12} style={{ paddingBottom: '10px', paddingTop: '10px' }}>
              <MeetingFilter
                displayOption={{
                  showHeader: true,
                  showFooter: true,
                  col: '3'
                }}
                style={{
                  paddingBottom: '10px',
                  maxHeight: '250px',
                  overflowY: 'auto'
                }}
                handleChange={handleChange}
                formData={filter}
                handleSubmit={handleSubmit}
                saveConfig={saveConfig}
              />
            </Col>
          )}
          <Col lg={12}>
            <MeetingTable
              data={meetings}
              paging={paging}
              total={total}
              nextPage={nextPage}
              previousPage={previousPage}
              gotoPage={gotoPage}
              colSort={colSort}
              handleSort={handleSort}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

RunningMeeting.propTypes = {
  meetingId: PropTypes.string,
  pageSize: PropTypes.number
};

export default RunningMeeting;
