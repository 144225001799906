import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const exportToExcel = (csvData, fileName, sheetName, columns) => {
    let excelData = csvData.map((p, index) => {
        let result = {};
        let rowIndex = index + 1;
        columns.forEach((c, idx) => {
            const value = c.accessor === 'no' ? rowIndex : p[c.accessor];
            result[columns[idx].Header] = value;
        });
        return result;
    });

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
}

function copyTableToClipboard(data, columns) {
    const convertTableToCSV = (data, columns) => {
        let csv = '';
        console.log(columns)
        const headerRow = columns.map(column => column.Header);
        csv += headerRow.join(',') + '\n';

        data.forEach((row, rowIndex) => {
            const rowData = columns.map(column => {
                if (column.accessor === 'no') {
                    return rowIndex + 1;
                } else {
                    return row[column.accessor];
                }
            });
            csv += rowData.join(',') + '\n';
        });

        return csv;
    };

    const csvData = convertTableToCSV(data, columns);

    console.log(csvData)
    const textField = document.createElement('textarea');
    textField.style.position = 'fixed';
    textField.style.opacity = 0;
    textField.value = csvData;

    document.body.appendChild(textField);

    textField.select();
    document.execCommand('copy');

    textField.remove();
}


export { exportToExcel, copyTableToClipboard }