import axios from 'axios';
import { URL } from '../helpers/urlHelper';

axios.defaults.withCredentials = true;

const getAllFullProjects = async () => {
    const res = await axios({
        method: 'get',
        url: `${URL}/projects`,
    })
        .catch((error) => {
            console.log('AXIOS ERROR', error)
            return [];
        });

    if (res.status !== 200)
        return [];

    return res.data;
}

const getAllCondensedProjects = async () => {
    const res = await axios({
        method: 'get',
        url: `${URL}/projects/condensed`
    })
        .catch(err => {
            console.log(err);
        });

    if (res.status !== 200)
        return [];

    return res.data;
}

const getProjectById = async (id) => {
    if (id === 0) return null;

    const res = await axios({
        method: 'get',
        url: `${URL}/projects/${id}`
    })
        .catch(err => console.log(err));

    if (res.status !== 200)
        return null;

    return res.data;
}

const postProject = async (project) => {
    if (!project) return false;

    const method = project.id > 0 ? 'patch' : 'post';

    project = {
        ...project,
        owners: project.owners.map(x => {
            return { Id: x.value, Name: x.name }
        }),
        champions: project.champions.map(x => {
            return { Id: x.value, Name: x.name }
        }),
    }

    console.log(`Project to ${method}`, project);

    const success = await manageProject(method, project);

    return success;
}

const manageProject = async (method, project) => {
    const res = await axios({
        method: method,
        url: `${URL}/projects${method === 'patch' ? '/update' : ''}`,
        data: { ...project },
        config: {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            }
        },
    })
        .catch((error) => {
            console.log('ERROR: ', { ...error });
            return false;
        });

    return res.data ? true : false;
}

export { getAllFullProjects, postProject, getAllCondensedProjects, getProjectById };
