import React, { useState } from "react";
import Minimise from "components/Minimise";
import PropTypes from 'prop-types';
import ActionsTable from "./ActionsTable";
import IconButton from "components/common/IconButton";
import ActionModal from "./ActionModal";

const ActionsManager = ({ actions, title, itemId }) => {
    const [showModal, setShowModal] = useState(false);
    const [stateAction, setStateAction] = useState(null);

    const displayModal = (action) => {
        setStateAction(action);
        setShowModal(true);
    }

    return (
        <>
        <div className="mx-4">
            <div className="d-flex h5 justify-content-between p-2 my-3">
                <span>Actions</span>
                <span><Minimise id={`${title}-action-collapse`} /></span>
            </div>
            <div id={`${title}-action-collapse`}>
                <ActionsTable actions={actions} modalShow={displayModal} />
                <div className="my-3 p-1">
                    <IconButton
                        variant="outline-warning"
                        icon="plus"
                        size="sm"
                        onClick={() => displayModal(null)}
                    >New Action</IconButton>
                </div>
            </div>
        </div>
        <ActionModal setVisible={setShowModal} visible={showModal} title={title} action={stateAction} itemId={itemId} />
        </>
    )
}

ActionsManager.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    itemId: PropTypes.number,
}

export default ActionsManager;