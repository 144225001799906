export const newArea = {
    id: 0,
    code: "",
    siteId: 0,
    description: "",
    typePrefix: ""
};
export const newBin = {
    id: 0,
    barcode: "",
    locationBarcode: "",
};
export const newSite = {
    id: 0,
    name: "",
    description: "",
};

export class ApiResponse {
    constructor(success = false, message = "Something when wrong!!", data = null) {
        this.success = success;
        this.message = message;
        this.data = data;
    }
}