import axios from 'axios';
import { URL } from '../../helpers/urlHelper';
import { BaseTableRS } from 'components/pages/stock-location/models/BaseTable';
import { objectToQueryString } from '../../helpers/utils'
import { ApiResponse } from '../../helpers/stockLocationHelpers'

axios.defaults.withCredentials = true;
const baseURL = `${URL}/sc-action-plan-comments`;

const searchComments = async (request) => {
    const res = await axios({
        method: 'get',
        url: `${baseURL}?${objectToQueryString(request)}`,
    })
        .catch((error) => {
            console.log('AXIOS ERROR', error)
            return new BaseTableRS([], 0);
        });

    if (res.status !== 200)
        return new BaseTableRS([], 0);

    return new BaseTableRS(res.data?.data?.items, res.data?.data?.total);
}

const createComment = async (request) => {
    const result = new ApiResponse();
    if (!request) return result;
    const res = await axios({
        method: 'post',
        url: baseURL,
        data: { ...request },
        config: {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            }
        },
    })
        .catch((error) => {
            console.log('ERROR: ', { ...error });
            return result;
        });
    if (res.status !== 200) {
        return result;
    }

    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}

const patchComment = async (data, id) => {
    const result = new ApiResponse();
    if (!data || id <= 0) return result;
    const res = await axios({
        method: 'patch',
        url: `${baseURL}/${id}`,
        data: { ...data },
        config: {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            }
        },
    })
        .catch((error) => {
            console.log('ERROR: ', { ...error });
            return result;
        });

    if (res.status !== 200) {
        return result;
    }
    result.success = res.data.success;
    result.data = res.data?.data
    result.message = res.data?.message;
    return result;
}

export {
    searchComments,
    createComment,
    patchComment
};

