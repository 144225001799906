import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { splitStringMapValueToDropDown } from 'helpers/dropdownFuncHelper';
import { getAllEvaUsers } from 'services/userServices';
import GroupUser from './GroupUser';

const UsersInCellWidget = ({ usersText, showName }) => {
  const [users, setUsers] = useState([]);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();
    getAllEvaUsers(controller.signal)
      .then(res => {
        if (!isMounted.current) return null;

        if (res?.length) setUsers(res);
      })
      .catch(err => {
        console.log('Error in user fetch', err);
      });

    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, []);
  const selectedUsers = splitStringMapValueToDropDown(usersText, users);
  return (
    <GroupUser
      avatarSize="l"
      users={selectedUsers}
      showMember={2}
      showName={showName}
      className="d-none d-md-block"
    />
  );  
};

UsersInCellWidget.propTypes = {
  usersText: PropTypes.string,
  showName: PropTypes.bool
};

export default UsersInCellWidget;
