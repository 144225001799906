import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const SaveBtn = ({ loading, text, loadingText, handleClick }) => {
  return (
    <Button onClick={() => handleClick()} disabled={loading}>
      {!loading ? (
        text
      ) : (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span>{loadingText}</span>
        </>
      )}
    </Button>
  );
};

SaveBtn.propTypes = {
  loading: PropTypes.bool,
  text: PropTypes.string,
  loadingText: PropTypes.string,
  handleClick: PropTypes.func
};

export default SaveBtn;
