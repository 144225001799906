import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import { signOut } from 'services/userServices';
import { useState } from 'react';
import { getItemFromStore } from 'helpers/utils';
import { useSelector } from 'react-redux';

const ProfileDropdown = () => {
  const nav = useNavigate();
  const [userName, setUser] = useState('');

  let name = useSelector(state => state.user.userName);

  const logout = async (e) => {
    e.preventDefault();
    await signOut();
    nav('/errors/authError');
  }

  useEffect(() => {
    if (!name)
      name = getItemFromStore('user', {}, sessionStorage)?.userName;

    if (name)
      setUser(name);
  }, [name])

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        {userName ? <Avatar name={userName} /> : <Avatar src={team3} />}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {/* <Dropdown.Item className="fw-bold text-warning" href="#!">
            <FontAwesomeIcon icon="crown" className="me-1" />
            <span>Go Pro</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href="#!">Set status</Dropdown.Item>
          <Dropdown.Item as={Link} to="/user/profile">
            Profile &amp; account
          </Dropdown.Item>
          <Dropdown.Item href="#!">Feedback</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/user/settings">
            Settings
          </Dropdown.Item> */}
          <Dropdown.Item as={Link} to="#" onClick={(event) => logout(event)}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
