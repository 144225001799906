import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Card } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import UserDropdown from 'components/Dropdowns/UserDropdown';
import DatePicker from 'react-datepicker';
import OptionListSelect from 'components/Dropdowns/OptionListSelect';

const ActionForm = ({ action, createAction, itemId }) => {
  const [newAction, setNewAction] = useState({
    itemId: itemId,
    assignedUsers: [],
    targetDate: new Date().toISOString(),
    comments: '',
    stageName: ''
  });
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    if (action) {
      setNewAction(action);
      if (action.assignedUsers)
        setSelectedUsers(
          action.assignedUsers.map(x => {
            return {
              ...x,
              label: x.name,
              value: x.id ? x.id : x.userId
            };
          })
        );
    }
  }, [action]);

  const responsibilityChange = options => {
    const users = options.map(x => {
      return {
        ...x,
        id: x.userId !== 0 ? x.userId : x.value
      };
    });
    setSelectedUsers([...users]);
    setNewAction({ ...newAction, assignedUsers: [...users] });
  };

  const dateHandle = date => {
    const newDate = new Date(date);
    setNewAction({ ...newAction, targetDate: newDate.toISOString() });
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>
                <Form.Label htmlFor="">Comments</Form.Label>
              </Card.Title>
              <Form.Control
                as="input"
                id="comments"
                name="comments"
                defaultValue={newAction ? newAction.comments : ''}
                onChange={e =>
                  setNewAction({ ...newAction, comments: e.target.value })
                }
              ></Form.Control>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>
                <Form.Label htmlFor="responsibility">Responsibility</Form.Label>
              </Card.Title>
              <UserDropdown
                selectedOptions={selectedUsers}
                handleChange={responsibilityChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>
                <Form.Label htmlFor="target">Target Completion Date</Form.Label>
              </Card.Title>
              <DatePicker
                id="target"
                className="form-control"
                placeholderText="Select Target Date"
                selected={
                  newAction ? new Date(newAction.targetDate) : new Date()
                }
                dateFormat="dd/MM/yyyy"
                onChange={date => dateHandle(date)}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>
                <Form.Label htmlFor="status">Status</Form.Label>
              </Card.Title>
              <OptionListSelect
                optionKey="action.stages"
                selected={newAction ? newAction.stageId : 0}
                changeFn={opt =>
                  setNewAction({
                    ...newAction,
                    stageName: opt.label,
                    stageId: opt.value
                  })
                }
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <IconButton
            icon="plus"
            variant="falcon-primary"
            className="me-2 mb-1"
            onClick={() => createAction(newAction)}
          >
            Save
          </IconButton>
        </Col>
      </Row>
    </>
  );
};

ActionForm.propTypes = {
  action: PropTypes.object,
  createAction: PropTypes.func,
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ActionForm;
