import {
  PermissionOperation,
  PermissionType,
  PermissionCode
} from '../helpers/permissionHelper';

export const appRoutes = {
  label: 'app',
  children: [
    {
      name: 'Home',
      icon: 'home',
      to: '/',
      active: true
    },
    {
      name: 'Back to EVA',
      icon: 'chevron-left',
      to: '//eva.emplas.co.uk/',
      active: true
    }
    // {
    //   name: 'Proile',
    //   icon: 'user',
    //   to: '/',
    //   active: true
    // },
  ]
};

export const management = {
  label: 'Management',
  children: [
    {
      permission_codes: [
        PermissionCode.MMMeetingDashboard,
        PermissionCode.MMCustFocus
      ],
      name: 'Meeting Management',
      active: true,
      icon: 'tasks', //'user-plus',
      children: [
        {
          permission_codes: [PermissionCode.MMMeetingDashboard],
          permission_required_type: PermissionOperation.Or,
          permissions: [
            PermissionType.View,
            PermissionType.Update,
            PermissionType.Create
          ],
          name: 'Meeting Dashboard',
          to: 'meeting-management/meeting-dashboard',
          exact: true,
          active: true
        },
        {
          permission_codes: [PermissionCode.MMCustFocus],
          permission_required_type: PermissionOperation.Or,
          permissions: [
            PermissionType.View,
            PermissionType.Update,
            PermissionType.Create
          ],
          name: 'Customer Focus Dashboard',
          to: 'meeting-management/customer-focus-dashboard',
          exact: true,
          active: true
        }
        // {
        //   name: 'Action Plans Manager',
        //   to: '/customer-service/action-plan-manager',
        //   active: true
        // },
      ]
    },
    {
      name: 'Customer service',
      active: true,
      icon: 'flag', //'user-plus',
      children: [
        {
          name: 'Meeting Diary',
          to: '/customer-service/meetings/calendar',
          exact: true,
          active: true
        },
        {
          name: 'Action Plans Board',
          to: '/customer-service/action-plan-board',
          exact: true,
          active: true
        }
        // {
        //   name: 'Action Plans Manager',
        //   to: '/customer-service/action-plan-manager',
        //   active: true
        // },
      ]
    },
    // {
    //   name: 'New Project Developments',
    //   active: true,
    //   icon: 'tasks',
    //   children: [
    //     {
    //       name: 'Project Dashboard',
    //       to: '/npd/dashboard',
    //       exact: true,
    //       active: true
    //     },
    //     {
    //       name: 'Project Manager',
    //       to: '/npd/project-manager',
    //       active: true
    //     },
    //   ]
    // },
    {
      name: 'New & Critical',
      active: true,
      icon: 'exclamation',
      children: [
        {
          name: 'New & Critical Dashboard',
          to: '/new-critical/nc-dashboard',
          exact: true,
          active: true
        }
      ]
    }, 
    // {
    //   name: 'HR',
    //   active: true,
    //   icon: 'exclamation',
    //   children: [
    //     {
    //       name: 'Skill Matrix',
    //       exact: true,
    //       active: true,
    //       children: [
    //         {
    //           name: 'Section',
    //           to: 'hr/skill-matrix/section/section-dashboard',
    //           exact: true,
    //           active: true
    //         }]
    //     }
    //   ]
    // },
    {
      name: 'Stock Location System',
      active: true,
      permission_codes: [
        PermissionCode.SlsLocations,
        PermissionCode.SlsAreas,
        PermissionCode.SLsStocks,
        PermissionCode.SlsBins,
        PermissionCode.SlsSites
      ],
      icon: 'globe',
      children: [
        {
          name: 'Sites Management',
          active: true,
          permission_codes: [PermissionCode.SlsSites],
          icon: '',
          children: [
            {
              name: 'Site Dashboard',
              permission_codes: [PermissionCode.SlsSites],
              permission_required_type: PermissionOperation.Or,
              permissions: [
                PermissionType.View,
                PermissionType.Update,
                PermissionType.Create
              ],
              to: 'stock-location/site-management/sites',
              exact: true,
              active: true
            },
            {
              name: 'Site Manager',
              permission_codes: [PermissionCode.SlsSites],
              permission_required_type: PermissionOperation.Or,
              permissions: [
                PermissionType.View,
                PermissionType.Update,
                PermissionType.Create
              ],
              to: 'stock-location/site-management/site-manager',
              exact: true,
              active: true
            }
          ]
        },
        {
          name: 'Areas Management',
          permission_codes: [PermissionCode.SlsAreas],
          active: true,
          icon: '',
          children: [
            {
              name: 'Area Dashboard',
              permission_codes: [PermissionCode.SlsAreas],
              permission_required_type: PermissionOperation.And,
              permissions: [PermissionType.View],
              to: 'stock-location/area-management/areas',
              exact: true,
              active: true
            },
            {
              name: 'Area Manager',
              permission_codes: [PermissionCode.SlsAreas],
              permission_required_type: PermissionOperation.Or,
              permissions: [
                PermissionType.View,
                PermissionType.Update,
                PermissionType.Create
              ],
              to: 'stock-location/area-management/area-manager',
              exact: true,
              active: true
            }
          ]
        },
        {
          name: 'Locations Management',
          active: true,
          permission_codes: [PermissionCode.SlsLocations],
          icon: '',
          children: [
            {
              name: 'Location Dashboard',
              permission_codes: [PermissionCode.SlsLocations],
              permission_required_type: PermissionOperation.And,
              permissions: [PermissionType.View],
              to: 'stock-location/location-management/locations',
              exact: true,
              active: true
            },
            {
              permission_codes: [PermissionCode.SlsLocations],
              permission_required_type: PermissionOperation.Or,
              permissions: [
                PermissionType.View,
                PermissionType.Update,
                PermissionType.Create
              ],
              name: 'Location Manager',
              to: 'stock-location/location-management/location-manager',
              exact: true,
              active: true
            }
          ]
        },
        {
          name: 'Bins Management',
          active: true,
          permission_codes: [PermissionCode.SlsBins],
          icon: '',
          children: [
            {
              name: 'Bin Dashboard',
              permission_codes: [PermissionCode.SlsBins],
              permission_required_type: PermissionOperation.And,
              permissions: [PermissionType.View],
              to: 'stock-location/bin-management/bins',
              exact: true,
              active: true
            },
            {
              name: 'Bin Manager',
              permission_codes: [PermissionCode.SlsBins],
              permission_required_type: PermissionOperation.Or,
              permissions: [
                PermissionType.View,
                PermissionType.Update,
                PermissionType.Create
              ],
              to: 'stock-location/bin-management/bin-manager',
              exact: true,
              active: true
            }
          ]
        },
        {
          name: 'Stocks Management',
          active: true,
          permission_codes: [PermissionCode.SLsStocks],
          icon: '',
          children: [
            {
              name: 'Stock Transactions',
              permission_codes: [PermissionCode.SLsStocks],
              permission_required_type: PermissionOperation.Or,
              permissions: [PermissionType.View],
              to: 'stock-location/stock-management/stock-transaction',
              exact: true,
              active: true
            },
            // {
            //   name: 'Stock Dashboard',
            //   to: 'stock-location/stock-management/stocks',
            //   exact: true,
            //   active: true
            // },
            {
              name: 'Stock Search',
              permission_codes: [PermissionCode.SLsStocks],
              permission_required_type: PermissionOperation.Or,
              permissions: [PermissionType.View],
              to: 'stock-location/stock-management/stock-search',
              exact: true,
              active: true
            }
          ]
        }
      ]
    }
  ]
};

export const adminRoutes = {
  label: 'Admin Management',
  children: [
    {
      permission_codes: [PermissionCode.PMSetup, PermissionCode.PMUserPm],
      name: 'Permissions Management',
      active: true,
      icon: 'lock',
      children: [
        {
          name: 'Permission Setup Management',
          permission_codes: [PermissionCode.PMSetup],
          permission_required_type: PermissionOperation.Or,
          permissions: [
            PermissionType.View,
            PermissionType.Update,
            PermissionType.Create
          ],
          active: true,
          icon: 'lock',
          children: [
            {
              permission_codes: [PermissionCode.PMSetup],
              permission_required_type: PermissionOperation.And,
              permissions: [PermissionType.View, PermissionType.Update],
              name: 'Permission Setup Dashboard',
              to: 'admin/permission/permissions-setup/permission-setup-dashboard',
              exact: true,
              active: true
            },
            {
              permission_codes: [PermissionCode.PMSetup],
              permission_required_type: PermissionOperation.And,
              permissions: [PermissionType.Create, PermissionType.View],
              name: 'Permission Setup Manager',
              to: 'admin/permission/permissions-setup/permission-setup-manager',
              exact: true,
              active: true
            }
          ]
        },
        {
          permission_codes: [PermissionCode.PMUserPm],
          permission_required_type: PermissionOperation.Or,
          permissions: [
            PermissionType.View,
            PermissionType.Update,
            PermissionType.Create
          ],
          name: 'User Permission Management',
          active: true,
          icon: 'user',
          children: [
            {
              permission_codes: [PermissionCode.PMUserPm],
              permission_required_type: PermissionOperation.And,
              permissions: [PermissionType.Update, PermissionType.View],
              name: 'User Permission Dashboard',
              to: 'admin/permission/user-permission/user-permission-dashboard',
              exact: true,
              active: true
            },
            {
              permission_codes: [PermissionCode.PMUserPm],
              permission_required_type: PermissionOperation.And,
              permissions: [PermissionType.Create, PermissionType.View],
              name: 'User Permission Manager',
              to: 'admin/permission/user-permission/user-permission-manager',
              exact: true,
              active: true
            }
          ]
        }
      ]
    }
  ]
};

export default [appRoutes, adminRoutes, management];
