import React from "react";
import { Table } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import PropTypes from "prop-types";
import dayjs from "dayjs";

const ActionsTable = ({ actions, title, itemId, modalShow }) => {

    const ManageActions = ({ action }) => {
        return (
            <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
                <IconButton
                    icon={'edit'}
                    variant="outline-dark"
                    size="sm"
                    className="border-300 me-1 text-600"
                    onClick={() => modalShow(action)}
                >Edit</IconButton>
            </div>
        )
    }

    return (
        <Table hover responsive className="text-center">
            <thead>
                <tr>
                    <th scope="col">Responsibility</th>
                    <th scope="col">Target Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Comments</th>
                    <th scope="col">Edit</th>
                </tr>
            </thead>
            <tbody>
                {actions && actions.length > 0 ?
                    actions.map((x, i) => {
                        return (
                            <tr key={`${i}-${title}`} className={`hover-actions-trigger ${((x.stageName && x.stageName === 'Completed') ? 'bg-soft-success' : '')}`}>
                                <td>{x.assignedUsers ? x.assignedUsers.map(x => x.name).join(', ') : ""}</td>
                                <td>{x.targetDate ? dayjs(x.targetDate).format("DD-MMMM-YYYY") : ""}</td>
                                <td>{x.stageName && x.stageName}</td>
                                <td>{x.comments ?? ""}</td>
                                <td className="w-auto">
                                    <ManageActions action={x} itemId={itemId} />
                                </td>
                            </tr>
                        )
                    }) :
                    (<tr><td colSpan={5}>No actions assigned! Assign a new action using the button below.</td></tr>)
                }
            </tbody>
        </Table>
    );
}

ActionsTable.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    itemId: PropTypes.number,
    modalShow: PropTypes.func,
    action: PropTypes.object
}

export default ActionsTable;