import React, {useState} from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FeedActionButtons from './FeedActionButtons';
import Comments from './Comments';
import AddComment from './AddComment';

const ActionPlanComment = ({ id, reactions, otherComments }) => {
  const [reload, setReload] = useState(false);
  const forkReloadComment = () => {
    console.log('reload');
    setReload(prev => !prev);
  };

  return (
    <Card.Footer className="bg-light pt-0" style={{ marginTop: '10px' }}>
      <FeedActionButtons id={id} reactions={reactions} />
      <AddComment parentId={id} handleSubmit={() => forkReloadComment()} />
      <Comments parentId={id} loadComment={otherComments} reload={reload}/>
    </Card.Footer>
  );
};

ActionPlanComment.propTypes = {
  id: PropTypes.string.isRequired,
  reactions: PropTypes.object,
  otherComments: PropTypes.string
};

export default ActionPlanComment;
