import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { getOptionsByKey } from "services/optionLists";

const OptionListSelect = ({ optionKey, selected, changeFn, disableStage = 0, isMulti = false, placeholder = 'Select...', enabledStage = 0 }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const controller = new AbortController();

        getOptionsByKey(optionKey, controller.signal)
            .then(res => {
                if (res && res.length) // Need to add label and name for display
                    setOptions(res.map(x => {
                        let disable = disableStage > 0 && disableStage === x.id;
                        if (enabledStage > 0) {
                            disable = x.id !== enabledStage;
                        }
                        return {
                            ...x, value: x.id, label: x.name, disabled: disable
                        }
                    }));
            })
            .catch(err => console.log('Error in option list fetch', err));

        return () => controller.abort();
    }, [optionKey, disableStage]);

    return (
        <Select
            closeMenuOnSelect={true}
            placeholder={placeholder}
            classNamePrefix="react-select"
            value={typeof selected === 'number' ? options.find(x => x.value === selected) : selected}
            onChange={opt => changeFn(opt)}
            options={options}
            isMulti={isMulti}
            isOptionDisabled={option => option.disabled}
        />
    )
}

OptionListSelect.propTypes = {
    optionKey: PropTypes.string,
    selected: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    changeFn: PropTypes.func,
    disableStage: PropTypes.number,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,
    enabledStage: PropTypes.number,
}

export default OptionListSelect;