import React, { useState, useEffect, useContext } from "react";
import { TransactionTableRQ } from "../models/TransactionTableRQ"
import { Button, Card, Row, Col, Form, Spinner } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { StockTransactionTable, StockTransactionColumns } from "components/partials/stock-location/transactions/StockTransactionTable";
import { searchTransactions } from 'services/stock-location/stockTransactionService'
import SlsProductDropdown from 'components/Dropdowns/SlsProductDropdown';
import { exportToExcel } from 'helpers/exportHelper'
import AppContext from 'context/Context';


export default function StockTransactionList() {
    const startRequest = new TransactionTableRQ(1, 200, "");
    const [transactions, setTransactions] = useState([]);
    const [total, setTotal] = useState(0);
    const [paging, setPaging] = useState(startRequest);

    const [loading, setLoading] = useState(false)
    const [product, setProduct] = useState({});

    const [pagingExport, setPagingExport] = useState(startRequest);
    const [excelDownloading, setExcelDownloading] = useState(false);

    const handleProductCodeChange = (option) => {
        setProduct(option);
        setPaging({ ...paging, productCode: option.productCode })
    };

    const {
        config: { isFluid, isNavbarVerticalCollapsed },
        setConfig
    } = useContext(AppContext);
    const [kanbanIsFluid] = useState(isFluid);
    const [kanbanIsNavbarVerticalCollapsed] = useState(isNavbarVerticalCollapsed);

    useEffect(() => {
        loadTransactions(startRequest);
    }, [])
    useEffect(() => {
        setConfig('isFluid', true);
        setConfig('isNavbarVerticalCollapsed', true);
        return () => {
            setConfig('isFluid', kanbanIsFluid);
            setConfig('isNavbarVerticalCollapsed', kanbanIsNavbarVerticalCollapsed);
        };
    }, []);

    const loadTransactions = async (pagingRq) => {
        setLoading(true);
        await searchTransactions(pagingRq).then(res => {
            if (res) {
                setTransactions(res.items);
                setTotal(res.total);
            }
        })
            .catch(err => {
                console.log(err);
            }).finally(() => {
                setLoading(false)
                setPaging(pagingRq);
            })
    }

    const handleExport = async (type) => {
        let request = { ...paging, isGetAll: true };
        setLoading(true);
        if (type == 'excel') {
            setExcelDownloading(true);
        }
        await searchTransactions(request).then(res => {
            if (res) {
                pagingExport.pageSize = res.total;
                pagingExport.pageNum = 1;
                setPagingExport(pagingExport);
                const pCode = product?.productCode ?? "All";
                if (type == 'excel') {
                    console.log(product)
                    exportToExcel(res.items, 'transactions_by_product_' + pCode, pCode, StockTransactionColumns);
                }
            }
        })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                if (type == 'excel') {
                    setExcelDownloading(false);
                }
                setLoading(false);
            });
    }

    const gotoPage = (index) => {
        index += 1;
        if (index <= 0) return;
        paging.pageNum = index;
        loadTransactions(paging);
    }

    const nextPage = () => {
        const page = paging.pageNum + 1;
        if (page > Math.ceil(total / paging.pageSize)) return;
        paging.pageNum = page;
        loadTransactions(paging);
    }

    const previousPage = () => {
        const page = paging.pageNum - 1;
        if (page <= 0) return;
        paging.pageNum = page;
        loadTransactions(paging);
    }

    return (
        <>
            <Col>
                <PageHeader
                    title="Stock Transactions"
                    description={`Manage and view all available Transactions from this page.`}
                    className="mb-3"
                ></PageHeader>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={3}> <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title className=" mb-3 mx-1">
                                        <div >Filter by: </div>
                                    </Card.Title>
                                    <div>
                                        <Row >
                                            <Form.Group className="mb-3">
                                                <SlsProductDropdown
                                                    selectedOptions={product}
                                                    handleChange={opts => handleProductCodeChange(opts)}
                                                    isMulti={false}
                                                />
                                            </Form.Group>
                                        </Row>
                                    </div>
                                    <div >
                                        <Button
                                            style={{
                                                float: 'center',
                                            }}
                                            variant={'primary'}
                                            onClick={() => loadTransactions(paging)}
                                        >Load
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                            </Col>
                            <Col>{transactions.length > 0 ? <Row style={{
                                paddingBottom: '10px'
                            }} className="align-items-center justify-content-between">
                                <Col md={9}>
                                </Col>
                                <Col md={2}>
                                    <Row className="align-items-center justify-content-between">
                                        <Col className="align-items-center justify-content-between">
                                            {!excelDownloading ? <Button
                                                style={{
                                                    width: '100%',
                                                    height: '60px'
                                                }}
                                                variant={'primary'}
                                                onClick={() => { handleExport('excel') }}
                                            >
                                                Export excel
                                            </Button> : <div className="text-center">
                                                <Spinner variant="primary" animation="border" role="status"></Spinner>
                                            </div>}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> : null}</Col>
                        </Row>

                    </Card.Header>
                    <Card.Body className="p-2 overflow-hidden">
                        <StockTransactionTable
                            transactions={transactions}
                            paging={paging}
                            total={total}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            gotoPage={gotoPage}
                            loading={loading}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </>
    )
}