import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import ActionPlanTableHeader from './ActionPlanTableHeader';
import ActionPlanTable from './ActionPlanTable';
import { joinDataMultiDropdown } from 'helpers/dropdownFuncHelper';
import { BaseTableRQ } from 'components/common/models/BaseClass';
import { searchActionPlan } from 'services/customer-service/scActionPlanService';
import ActionPlanFilter from './ActionPlanFilter';
import {
  KEY_ACTION_PLAN_LIST_CONFIG
} from 'services/base-services/userSettingServices';
import PropTypes from 'prop-types';
import { usePaginationBe, useHanleConfig  } from 'hooks/useBeHandle';

const RunningActionPlans = ({ meetingId }) => {
  const defaultRQ = { ...new BaseTableRQ(1, 10), meetingId: meetingId };
  const [actionPlans, setActionPlans] = useState([]);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({ ...defaultRQ, meetingId: meetingId });
  const [isFilter, setIsFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [colSort, setColSort] = useState(undefined);

  useEffect(() => {
    setIsFilter(false);
    getConfig();
  }, []);


  const toggelFilter = () => {
    setIsFilter(!isFilter);
  };


  const handleChange = (field, value) => {
    console.log(field, value)
    setFilter({
      ...filter,
      [field]: value
    });
  };

  const handleSubmit = () => {
    let rq = mapRQData(filter);
    loadActionPlans({ ...defaultRQ, ...rq });
  };

  const mapRQData = data => {
    if (data) {
      let mapRq = { ...data };
      if (data?.targetDate && data?.targetDate instanceof Date) {
        mapRq.targetDate = data?.targetDate?.toISOString();
      }

      mapRq.progresses = joinDataMultiDropdown(data?.progresses);
      mapRq.reporters = joinDataMultiDropdown(data?.reporters);
      mapRq.priorities = joinDataMultiDropdown(data?.priorities);
      mapRq.assignees = joinDataMultiDropdown(data?.assignees);
      mapRq.requestParticipants = joinDataMultiDropdown(
        data?.requestParticipants
      );
      mapRq.departments = joinDataMultiDropdown(data?.departments);
      return mapRq;
    }
    return null;
  };

  const loadActionPlans = pagingRq => {
    console.log(pagingRq)
    searchActionPlan(pagingRq)
      .then(res => {
        if (res) {
          setActionPlans(res.items);
          setTotal(res.total);
          let cl = {
            ...pagingRq,
            sortBy: pagingRq.sortBy,
            desc: pagingRq.desc,
            isBeSortedDesc: pagingRq.desc,
            isBeSorted: pagingRq.sortBy != undefined && pagingRq.sortBy != ''
          };
          setColSort(cl);
          setPaging(cl);
          setFilter(cl);
        }
      })
      .catch(err => {
        console.log(err);
      });
    setPaging(pagingRq);
  };

  const defaultPaging = data => {
    return {...data, 
      pageNum : defaultRQ.pageNum,
      pageSize : defaultRQ.pageSize,
      isLike : defaultRQ.isLike,
      isGetAll : defaultRQ.isGetAll,
      meetingId: meetingId
    }
  };

  const { gotoPage, nextPage, previousPage, handleSort } = usePaginationBe(paging, total, loadActionPlans, filter, setFilter);
  const { getConfig, saveConfig } = useHanleConfig(
    KEY_ACTION_PLAN_LIST_CONFIG,
    filter,
    mapRQData,
    defaultRQ,
    defaultPaging,
    loadActionPlans
  );
  return (
    <Card>
      <Row style={{ padding: '10px' }}>
        <ActionPlanTableHeader
          toggelFilter={() => toggelFilter()}
          meetingId={meetingId}
          style={{ padding: '10px' }}
        />
        <Col lg={isFilter ? 9 : 12}>
          <ActionPlanTable
            data={actionPlans}
            paging={paging}
            total={total}
            nextPage={nextPage}
            previousPage={previousPage}
            gotoPage={gotoPage}
            colSort={colSort}
            handleSort={handleSort}
          />
        </Col>
        {isFilter && (
          <Col lg={3}>
            <ActionPlanFilter
              handleChange={handleChange}
              formData={filter}
              saveConfig={saveConfig}
              handleSubmit={handleSubmit}
            />
          </Col>
        )}
      </Row>
    </Card>
  );
};

RunningActionPlans.propTypes = {
  meetingId: PropTypes.string
};

export default RunningActionPlans;
